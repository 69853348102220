import {
  Box,
  Button,
  Checkbox,
  Container,
  List,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import AgentCard from "./AgentCard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DraggableDialog from "../DraggableDialog";
import { useState, useEffect } from "react";
import ConfirmationDialog from "../ConfirmationDialog";

import { useConversationsApi } from "../../api/conversationsApi";

const Agents = () => {
  const theme = useTheme();
  const {
    getAvailableAgentCapabilities,
    getAgents,
    createAgent,
    deleteAgent,
    updateAgent,
  } = useConversationsApi();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);

  const [agents, setAgents] = useState([]);
  const [availableCapabilities, setAvailableCapabilities] = useState([]);

  useEffect(() => {
    getAvailableAgentCapabilities().then((response) => {
      setAvailableCapabilities(response);
    });

    getAgents().then((response) => {
      setAgents(response);
    });
  }, []);

  const zippyAgent = {
    id: "zippy",
    name: "Zippy",
    description: "A general purpose AI agent",
    image_url: "/zippy.png",
    system_prompt: "How can I help you today?",
    capabilities: availableCapabilities.map((c) => c.name),
  };

  const [editAgentDialogOpen, setEditAgentDialogOpen] = useState(false);
  const [editAgentDialogContent, setEditAgentDialogContent] = useState(null);
  const [editAgentDetails, setEditAgentDetails] = useState(null);

  const showDeleteAgentConfirmation = (agent) => {
    setAgentToDelete(agent);
    setOpenConfirmationDialog(true);
  };

  const onConfirmDeleteAgent = async () => {
    setOpenConfirmationDialog(false);

    // Delete the agent from the API
    await deleteAgent(agentToDelete.id);

    // Refetch the agents
    const response = await getAgents();
    setAgents(response);
  };

  const handleEditAgentDialogClose = (event) => {
    event.stopPropagation();

    setEditAgentDialogContent(null);
    setEditAgentDialogOpen(false);
  };

  const handleEditAgent = async (event) => {
    event.stopPropagation();

    if (!editAgentDetails) {
      return;
    }

    // If the agent has an ID, then we are updating an existing agent
    // If the agent does not have an ID, then we are creating a new agent
    if (editAgentDetails.id) {
      // Update the agent
      await updateAgent(
        editAgentDetails.id,
        editAgentDetails.name,
        editAgentDetails.description,
        editAgentDetails.system_prompt,
        editAgentDetails.role,
        editAgentDetails.capabilities
      );
    } else {
      // Create the agent
      await createAgent(
        editAgentDetails.name,
        editAgentDetails.description,
        editAgentDetails.system_prompt,
        editAgentDetails.role,
        editAgentDetails.capabilities
      );
    }

    setEditAgentDialogOpen(false);

    // Refetch the agents
    const response = await getAgents();
    setAgents(response);
  };

  const showEditAgentDialog = (agent) => {
    // If the agent is null, then we are adding a new agent
    if (!agent) {
      setEditAgentDetails({
        name: "",
        description: "",
        image_url: "",
        system_prompt: "",
        role: "",
        capabilities: [],
      });
    } else {
      setEditAgentDetails(agent);
    }
    setEditAgentDialogOpen(true);
  };

  useEffect(() => {
    if (!editAgentDetails) {
      return;
    }

    setEditAgentDialogContent(
      <Box sx={{ margin: 1, display: "flex", flexDirection: "column" }}>
        <TextField
          label="Agent Name"
          value={editAgentDetails.name}
          onChange={(e) => {
            let newDetails = { ...editAgentDetails };
            newDetails.name = e.target.value;
            setEditAgentDetails(newDetails);
          }}
          sx={{ margin: 1, minWidth: "300px" }}
        />
        <TextField
          label="Description"
          value={editAgentDetails.description}
          onChange={(e) => {
            let newDetails = { ...editAgentDetails };
            newDetails.description = e.target.value;
            setEditAgentDetails(newDetails);
          }}
          sx={{ margin: 1, minWidth: "300px" }}
        />
        <TextField
          label="Role"
          value={editAgentDetails.role}
          multiline
          onChange={(e) => {
            let newDetails = { ...editAgentDetails };
            newDetails.role = e.target.value;
            setEditAgentDetails(newDetails);
          }}
          sx={{ margin: 1, minWidth: "300px" }}
        />
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.warning.light,
            margin: 1,
            fontStyle: "italic",
          }}
        >
          The role of the Agent is used to determine how the coordinator will
          assign tasks agents. Agents will only be assigned tasks that match
          their role.
        </Typography>
        <TextField
          label="System Prompt"
          value={editAgentDetails.system_prompt}
          multiline
          onChange={(e) => {
            let newDetails = { ...editAgentDetails };
            newDetails.system_prompt = e.target.value;
            setEditAgentDetails(newDetails);
          }}
          sx={{ margin: 1, minWidth: "300px" }}
        />
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.warning.light,
            margin: 1,
            fontStyle: "italic",
          }}
        >
          The more detailed the system prompt, the better the agent will
          perform. Be specific and detailed in your instructions, and the agent
          will be able to better understand and respond to user input.
        </Typography>
        <List>
          {availableCapabilities.map((capability) => (
            <Box
              key={capability.name}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Checkbox
                checked={editAgentDetails.capabilities.includes(
                  capability.name
                )}
                onChange={(e) => {
                  let newDetails = { ...editAgentDetails };
                  if (e.target.checked) {
                    // Add the capability to the array
                    newDetails.capabilities.push(capability.name);
                  } else {
                    // Remove the capability from the array
                    newDetails.capabilities = newDetails.capabilities.filter(
                      (c) => c !== capability.name
                    );
                  }
                  setEditAgentDetails(newDetails);
                }}
                sx={{ p: 0, mx: 1, my: 0.5 }}
              />
              <ListItemText
                primary={capability.display_name}
                secondary={capability.description}
              />
            </Box>
          ))}
        </List>

        <Typography
          variant="caption"
          sx={{
            color: theme.palette.warning.light,
            margin: 1,
            fontStyle: "italic",
          }}
        >
          The fewer the number of capabilities, the more specialized the agent
          will be. Agents with more capabilities may be slower to respond to
          user input.
        </Typography>
      </Box>
    );
  }, [editAgentDetails, availableCapabilities]);

  return (
    <Container
      disableGutters
      id="agents-container"
      maxWidth={false}
      sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        color: theme.palette.text.primary,
        height: "calc(100dvh - 64px)",
      }}
    >
      {/* Header box that explains what the agents are, as well as a button to add an agent */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          bgcolor: theme.palette.primary.main,
          color: theme.palette.secondary.contrastText,
        }}
      >
        <Typography variant="h5" component="h1">
          Agents
          <Typography variant="body2" component="p">
            Agents are specialized AI bots that can be used to perform various
            tasks.
          </Typography>
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          startIcon={<PersonAddIcon />}
          onClick={() => showEditAgentDialog(null)}
        >
          Add Agent
        </Button>
      </Box>

      {/* Render the list of agents */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          p: 1,
          m: 1,
          borderRadius: 2,
          justifyContent: "center",
        }}
      >
        <AgentCard
          agent={zippyAgent}
          informationText="When Zippy is alone in a conversation, it will use all of the available capabilities listed here.  When Zippy is participating in a conversation with other agents, it will act as a coordinator and not perform any tasks itself."
          availableCapabilities={availableCapabilities}
        />
        {agents.map((agent, index) => (
          <AgentCard
            agent={agent}
            onEditAgent={showEditAgentDialog}
            onDeleteAgent={showDeleteAgentConfirmation}
            availableCapabilities={availableCapabilities}
          />
        ))}
      </Box>

      {/* Dialog for editing an agent */}
      <DraggableDialog
        open={editAgentDialogOpen}
        confirmationButtonText={"Save Agent"}
        cancellationButtonText={"Cancel"}
        content={editAgentDialogContent}
        onConfirm={handleEditAgent}
        onCancel={handleEditAgentDialogClose}
        title={"Agent Details"}
      />
      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        title={"Delete Agent"}
        content={
          "Are you sure you want to delete this agent?  It will also be removed from any conversations that it is currently active in."
        }
        onConfirm={onConfirmDeleteAgent}
      />
    </Container>
  );
};

export default Agents;
