import React, { useState, useEffect, useContext } from "react";

import {
  Button,
  Collapse,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";

import { IoIosCreate } from "react-icons/io";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { ConversationContext } from "../providers/ConversationProvider";

import { useTheme } from "@mui/material/styles";
import ConversationSettingsMenu from "./ConversationSettingsMenu";
import useTime from "../hooks/useTime";
import { useNavigate } from "react-router-dom";

function ConversationList({ handleDrawerClose }) {
  const { getLocalTime } = useTime();

  const [openSections, setOpenSections] = useState({});

  const navigate = useNavigate();

  const {
    selectedConversation,
    fetchConversations,
    conversationsWithHeadings,
    selectedConversationSection,
    setSelectedConversation,
  } = useContext(ConversationContext);

  const theme = useTheme();

  useEffect(() => {
    // Open the selected conversation section
    if (
      selectedConversationSection &&
      selectedConversationSection !== undefined &&
      !openSections[selectedConversationSection]
    ) {
      setOpenSections((prevState) => ({
        ...prevState,
        [selectedConversationSection]: true,
      }));
    }
  }, [selectedConversationSection]);

  const toggleSection = (name) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleNewConversation = async () => {
    navigate("/conversations");
    setSelectedConversation("new");

    if (handleDrawerClose) {
      handleDrawerClose();
    }
  };

  const handleConversationClick = (conversationId) => {
    // Navigate to the conversation /conversations/:id
    navigate(`/conversations/${conversationId}`);

    if (handleDrawerClose) {
      handleDrawerClose();
    }
  };

  // useEffect for the refresh of hte page, which should load the last selected conversation
  useEffect(() => {
    fetchConversations();
  }, []);

  // Filter to get unique section headings
  const uniqueSectionHeadings = [
    ...new Set(
      conversationsWithHeadings.map(
        (conversation) => conversation.sectionHeading
      )
    ),
  ];

  const orderedSectionHeadings = [
    ...uniqueSectionHeadings.filter(
      (heading) =>
        heading === "Today" ||
        heading === "Previous 7 days" ||
        heading === "This month"
    ),
    ...uniqueSectionHeadings.filter(
      (heading) =>
        heading !== "Today" &&
        heading !== "Previous 7 days" &&
        heading !== "This month"
    ),
  ];

  return (
    <Box>
      <List
        id="conversation-list"
        sx={{
          width: "100%",
          maxWidth: 360,
        }}
      >
        <ListItem
          sx={{
            color: theme.palette.primary.light,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: ".85rem",
            justifyContent: "center",
            display: "inline-flex",
            paddingRight: "0px",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleNewConversation}
            sx={{
              justifyContent: "flex-start",
              alignItems: "center",
              display: "inline-flex",
              fontWeight: theme.typography.fontWeightBold,
              fontSize: ".75rem",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                pr: 1,
                fontWeight: theme.typography.fontWeightBold,
                fontSize: ".75rem",
                color: theme.palette.primary.contrastText,
              }}
            >
              New Conversation
            </Typography>
            <IoIosCreate
              className="new-conversation-icon"
              size={25}
              color={theme.palette.primary.light}
            />
          </Button>
          <ChevronLeftIcon
            sx={{
              position: "relative",
              right: "-25px",
              color: theme.palette.primary.main,
              cursor: "pointer",
              display: { sm: "none" },
            }}
            onClick={handleDrawerClose}
          />
        </ListItem>
        {orderedSectionHeadings.map((sectionHeading) => (
          <List
            // component={"div"}
            disablePadding
            id={"sub-header-" + sectionHeading}
            key={"sub-header-" + sectionHeading}
          >
            <ListItemButton
              id={"toggle-" + sectionHeading}
              key={"toggle-" + sectionHeading}
              onClick={() => toggleSection(sectionHeading)}
              sx={{ height: 40 }}
            >
              <ListItem
                sx={{
                  color: theme.palette.primary.light,
                  fontWeight: theme.typography.fontWeightBold,
                  fontSize: ".75rem",
                }}
              >
                {sectionHeading}
              </ListItem>
              {openSections[sectionHeading] ? (
                <MdExpandLess />
              ) : (
                <MdExpandMore />
              )}
            </ListItemButton>
            <Collapse
              key={"collapse-" + sectionHeading}
              in={openSections[sectionHeading]}
              timeout="auto"
              unmountOnExit
            >
              {conversationsWithHeadings
                .filter(
                  (conversation) =>
                    conversation.sectionHeading === sectionHeading
                )
                .map((conversation) => (
                  <ListItemButton
                    key={"conversation-" + conversation.id}
                    selected={selectedConversation === conversation.id}
                    onClick={() => handleConversationClick(conversation.id)}
                    sx={{ pt: 0, pb: 0 }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        flexDirection: "row",
                      }}
                    >
                      <ListItem
                        sx={{
                          fontWeight: theme.typography.fontWeightBold,
                          fontSize: ".85rem",
                          pr: 0,
                          pb: 0,
                          pt: 0,
                        }}
                      >
                        <ListItemText
                          primary={conversation.conversation_summary}
                          secondary={getLocalTime(
                            conversation.record_modified
                          ).toLocaleString()}
                          primaryTypographyProps={{
                            fontWeight: theme.typography.fontWeightBold,
                            fontSize: ".85rem",
                          }}
                          secondaryTypographyProps={{
                            fontWeight: theme.typography.fontWeightBold,
                            fontSize: ".6rem",
                          }}
                        />
                        <ConversationSettingsMenu
                          conversationId={conversation.id}
                        />
                      </ListItem>
                    </Box>
                  </ListItemButton>
                ))}
            </Collapse>
          </List>
        ))}
      </List>
    </Box>
  );
}

export default ConversationList;
