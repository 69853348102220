import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListTwoToneIcon from "@mui/icons-material/ListTwoTone";
import { useTheme } from "@mui/material/styles";
import { Box, Fade, Snackbar } from "@mui/material";
import { useConversationsApi } from "../../../api/conversationsApi";
import { ConversationContext } from "../providers/ConversationProvider";

export default function MessageSettingsMenu({
  conversationId,
  message,
  setCopiedToClipboardOpen,
  isCurrentMessage = false,
}) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { deleteMessage } = useConversationsApi();

  const { requestMessagesRefresh, setCurrentMessage } =
    React.useContext(ConversationContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Delete the message
  const handleDelete = async (e) => {
    setAnchorEl(e.currentTarget);
    try {
      await deleteMessage(conversationId, message.id);

      // If the message is the current message, clear the current message
      if (isCurrentMessage) {
        setCurrentMessage(null);
      }
    } catch (error) {
      console.error("Error deleting message:", error);
    } finally {
      handleClose();
      requestMessagesRefresh();
    }
  };

  // Copy the message
  const handleCopyMessage = (e) => {
    setCopiedToClipboardOpen(true);
    setAnchorEl(e.currentTarget);
    navigator.clipboard.writeText(message.content);
    handleClose();
  };

  // Edit the message
  const handleEdit = (e) => {
    setAnchorEl(e.currentTarget);
    handleClose();
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <React.Fragment>
        <Button
          variant="text"
          onClick={handleClick}
          sx={{
            color: theme.palette.primary.contrastText,
            ":hover": {
              color: theme.palette.primary.main,
              bgcolor: "transparent",
            },
            alignItems: "right",
          }}
        >
          <ListTwoToneIcon />
        </Button>
        <Menu
          id="conversation-message-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem disabled={!message.id} onClick={handleDelete}>
            Delete
          </MenuItem>
          <MenuItem onClick={handleCopyMessage}>Copy Message</MenuItem>
          {/* <MenuItem onClick={handleEdit} disabled>
            Edit
          </MenuItem> */}
        </Menu>
      </React.Fragment>
    </Box>
  );
}
