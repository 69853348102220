import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Goals = ({ goals, sidebarSize }) => {
  const theme = useTheme();

  // Set the sidebar size to 75px if the screen size is less than sm
  if (!useMediaQuery(theme.breakpoints.up("sm"))) {
    sidebarSize = 75;
  } else {
    sidebarSize = sidebarSize + 75;
  }

  const displayWidth = "calc(100vw - " + sidebarSize + "px)";
  const displayMaxWidth = "calc(" + displayWidth + " - 30px)";

  return (
    <Box>
      <List
        sx={{
          mt: 5,
          ml: 5,
          pb: 0,
          mb: 0,
          borderRadius: "15px",
          width: displayWidth,

          color: theme.palette.text.primary,
          bgcolor: theme.palette.conversation.bot.bgcolor,
        }}
      >
        {goals.map((goal, index) => (
          <ListItem
            id={"goal-item-" + index}
            key={"goal-item-" + index}
            secondaryAction={
              <IconButton edge="end" aria-label="edit">
                <EditIcon sx={{ color: theme.palette.primary.main }} />
              </IconButton>
            }
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: displayMaxWidth,
              }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    variant="body"
                    sx={{ color: theme.palette.primary.contrastText }}
                  >
                    {goal.specific}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="caption"
                    paragraph
                    sx={{ fontStyle: "italic" }}
                  >
                    {goal.relevant}
                  </Typography>

                  <Typography variant="caption" paragraph>
                    {goal.measurable}
                  </Typography>
                  <Typography variant="caption" paragraph>
                    {goal.achievable}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Goals;
