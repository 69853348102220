import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Link,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useAuthApi } from "../../api/authApi";
import { useUserApi } from "../../api/userApi";

const Integrations = ({ mode }) => {
  const theme = useTheme();

  const [selectedTabValue, setSelectedTabValue] = useState(0);
  const [gitHubStatus, setGitHubStatus] = useState({ status: "unauthorized" });
  const [gitLabStatus, setGitLabStatus] = useState({ status: "unauthorized" });
  const [googleStatus, setGoogleStatus] = useState({ status: "unauthorized" });

  const { oauthLogin } = useAuthApi();
  const { fetchOAuthStatus } = useUserApi();

  const authorizeWithGitHub = async () => {
    await oauthLogin("github");
  };

  const authorizeWithGitLab = async () => {
    await oauthLogin("gitlab");
  };

  const authorizeWithGoogle = async () => {
    await oauthLogin("google");
  };

  useEffect(() => {
    const fetchOAuthStatuses = async () => {
      try {
        const gitHubStatus = await fetchOAuthStatus("github");
        const gitLabStatus = await fetchOAuthStatus("gitlab");
        const googleStatus = await fetchOAuthStatus("google");

        setGitHubStatus(gitHubStatus);
        setGitLabStatus(gitLabStatus);
        setGoogleStatus(googleStatus);
      } catch (error) {
        console.error("Error fetching OAuth statuses:", error);
      }
    };

    fetchOAuthStatuses();
  }, []);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`integrations-tabpanel-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTabValue(newValue);
  };

  return (
    <Container
      disableGutters
      id="integrations-container"
      maxWidth={false}
      sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        color: theme.palette.text.primary,
        height: "calc(100dvh - 64px)",
        // bgcolor: "red",
        alignContent: "center",
        justifyContent: "cneter",
        alignItems: "center",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs centered value={selectedTabValue} onChange={handleTabChange}>
          <Tab label="Code" />
          <Tab label="Email" />
          <Tab label="Social" />
        </Tabs>
      </Box>
      <CustomTabPanel value={selectedTabValue} index={0}>
        <Box
          id="code-box"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "8px",
            alignSelf: "flex-start",
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
            spacing: 4,
          }}
        >
          <Card sx={{ maxWidth: "450px", m: 3 }}>
            <CardHeader
              sx={{
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
              avatar={
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Avatar
                    src={
                      mode === "light"
                        ? "/github-mark.svg"
                        : "/github-mark-white.svg"
                    }
                    alt="GitHub"
                    sx={{
                      mr: 2,
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    GH
                  </Avatar>
                  <img
                    src={
                      mode === "light"
                        ? "/GitHub_Logo.png"
                        : "/GitHub_Logo_White.png"
                    }
                    height={"30px"}
                    alt="GitHub"
                  />
                </Box>
              }
            ></CardHeader>
            <CardContent
              spacing={2}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography variant="body2">
                <Link href="https://github.com">GitHub</Link> is a provider of
                Internet hosting for software development and version control
                using Git.{" "}
              </Typography>
              <Box
                sx={{
                  mt: "10px",
                  display:
                    gitHubStatus.status === "authorized" ? "flex" : "none",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CheckCircleIcon sx={{ color: theme.palette.success.light }} />
                <Typography
                  variant="body2"
                  sx={{ textJustify: "center", ml: 1 }}
                >
                  You are authorized with GitHub
                </Typography>
              </Box>

              <Button
                variant="filled"
                sx={{
                  color: theme.palette.primary.contrastText,
                  fontWeight: "bold",
                  bgcolor: theme.palette.primary.light,
                  margin: "20px",
                }}
                onClick={authorizeWithGitHub}
              >
                {gitHubStatus.status === "authorized"
                  ? "Reauthorize "
                  : "Authorize "}
                with GitHub
              </Button>
            </CardContent>
          </Card>
          <Card sx={{ maxWidth: "450px", m: 3 }}>
            <CardHeader
              sx={{
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
              avatar={
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Avatar
                    src={"/gitlab-logo-500.png"}
                    alt="GitLab"
                    sx={{
                      mr: 2,
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    GL
                  </Avatar>
                  <Typography variant="h5" sx={{ fontStyle: "bold" }}>
                    GitLab
                  </Typography>
                </Box>
              }
            ></CardHeader>
            <CardContent
              spacing={2}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography variant="body2">
                <Link href="https://gitlab.com">GitLab</Link> is a web-based
                DevOps lifecycle tool that provides a Git-repository manager,
                wiki, issue-tracking, and CI/CD pipeline features.{" "}
              </Typography>
              <Box
                sx={{
                  mt: "10px",
                  display:
                    gitLabStatus.status === "authorized" ? "flex" : "none",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CheckCircleIcon sx={{ color: theme.palette.success.light }} />
                <Typography
                  variant="body2"
                  sx={{ textJustify: "center", ml: 1 }}
                >
                  You are authorized with GitLab
                </Typography>
              </Box>

              <Button
                variant="filled"
                sx={{
                  color: theme.palette.primary.contrastText,
                  fontWeight: "bold",
                  bgcolor: theme.palette.primary.light,
                  margin: "20px",
                }}
                onClick={authorizeWithGitLab}
              >
                {gitLabStatus.status === "authorized"
                  ? "Reauthorize "
                  : "Authorize "}
                with GitLab
              </Button>
            </CardContent>
          </Card>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabValue} index={1}>
        <Box
          id="email-box"
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "3px",
            alignSelf: "flex-start",
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Card sx={{ maxWidth: "450px", m: 3 }}>
            <CardHeader
              sx={{
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
              avatar={
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <img src={"/google_icon.png"} height={"30px"} alt="Google" />
                  <Typography variant="h5" sx={{ ml: 2, fontStyle: "bold" }}>
                    Google
                  </Typography>
                </Box>
              }
            ></CardHeader>
            <CardContent
              spacing={2}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography variant="body2">
                <Link href="https://google.com">Google</Link> is a multinational
                technology company specializing in Internet-related services and
                products, including search engines, online advertising
                technologies, and cloud computing.{" "}
              </Typography>
              <Box
                sx={{
                  mt: "10px",
                  display:
                    googleStatus.status === "authorized" ? "flex" : "none",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CheckCircleIcon sx={{ color: theme.palette.success.light }} />
                <Typography
                  variant="body2"
                  sx={{ textJustify: "center", ml: 1 }}
                >
                  You are authorized with Google
                </Typography>
              </Box>

              <Button
                variant="filled"
                sx={{
                  color: theme.palette.primary.contrastText,
                  fontWeight: "bold",
                  bgcolor: theme.palette.primary.light,
                  margin: "20px",
                }}
                onClick={authorizeWithGoogle}
              >
                {googleStatus.status === "authorized"
                  ? "Reauthorize "
                  : "Authorize "}
                with Google
              </Button>
            </CardContent>
          </Card>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabValue} index={2}>
        <Box
          id="email-box"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "8px",
            alignSelf: "flex-start",
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
            spacing: 4,
          }}
        >
          <Typography
            sx={{
              color: theme.palette.primary.main,
              justifyContent: "center",
              fontSize: ".85rem",
            }}
          >
            Social integrations coming soon!
          </Typography>
        </Box>
      </CustomTabPanel>
    </Container>
  );
};

export default Integrations;
