import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useUserApi } from "../../api/userApi";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Divider,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import DraggableDialog from "../DraggableDialog";

export default function BetaApplications() {
  const theme = useTheme();

  const [acceptApplicationDialogOpen, setAcceptApplicationDialogOpen] =
    useState(false);
  const [acceptApplicationDialogContent, setAcceptApplicationDialogContent] =
    useState(null);
  const [applicationDetails, setApplicationDetails] = useState(null);

  const [rows, setRows] = useState([]);
  const { fetchBetaApplications, createNewUser } = useUserApi();

  useEffect(() => {
    // Get the list of beta applications
    fetchBetaApplications().then((response) => {
      setRows(response);
    });
    // Store it in the rows
  }, []);

  const handleAcceptApplicationDialogClose = (event) => {
    event.stopPropagation();

    setAcceptApplicationDialogContent(null);
    setAcceptApplicationDialogOpen(false);
  };

  const handleAcceptApplication = async (event) => {
    event.stopPropagation();

    const userDetails = {
      name: applicationDetails.name,
      email: applicationDetails.email,
      password: applicationDetails.password,
    };

    // Create a new user with the details
    await createNewUser(userDetails);

    setAcceptApplicationDialogOpen(false);

    fetchBetaApplications().then((response) => {
      setRows(response);
    });
  };

  useEffect(() => {
    if (!applicationDetails) {
      return;
    }

    setAcceptApplicationDialogContent(
      <Box sx={{ margin: 1, display: "flex", flexDirection: "column" }}>
        <TextField
          label="Name"
          value={applicationDetails.name}
          onChange={(e) => {
            let newDetails = { ...applicationDetails };
            newDetails.name = e.target.value;
            setApplicationDetails(newDetails);
          }}
          sx={{ margin: 1, minWidth: "300px" }}
        />
        <TextField
          label="Email"
          value={applicationDetails.email}
          onChange={(e) => {
            let newDetails = { ...applicationDetails };
            newDetails.email = e.target.value;
            setApplicationDetails(newDetails);
          }}
          sx={{ margin: 1, minWidth: "300px" }}
        />
        <TextField
          label="Password"
          value={applicationDetails.password}
          onChange={(e) => {
            let newDetails = { ...applicationDetails };
            newDetails.password = e.target.value;
            setApplicationDetails(newDetails);
          }}
          sx={{ margin: 1, minWidth: "300px" }}
        />
        <Typography
          variant="caption"
          sx={{ color: theme.palette.warning.light, margin: 1 }}
        >
          Note: Make sure you copy this password to send to the user before
          accepting the application! You will not be able to see it again.
        </Typography>
      </Box>
    );
    setAcceptApplicationDialogOpen(true);
  }, [applicationDetails]);

  const showAcceptApplicationDialog = (row) => {
    row.password = "";

    setApplicationDetails(row);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6">User?</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Name</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6">Email</Typography>
              </TableCell>
              <TableCell align="justify">
                <Typography variant="h6">Use Case</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.user_id ? (
                    <Tooltip title="Beta Application Accepted">
                      <ThumbUpAltIcon
                        sx={{ color: theme.palette.success.light }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Accept Beta Application">
                      <PersonAddAlt1Icon
                        onClick={() => showAcceptApplicationDialog(row)}
                        sx={{
                          color: theme.palette.primary.light,
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell align="left">
                  <Link to={`mailto:${row.email}`}> {row.email}</Link>
                </TableCell>
                <TableCell align="justify">{row.use_case}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DraggableDialog
        open={acceptApplicationDialogOpen}
        confirmationButtonText={"Promote to User"}
        cancellationButtonText={"Cancel"}
        content={acceptApplicationDialogContent}
        onConfirm={handleAcceptApplication}
        onCancel={handleAcceptApplicationDialogClose}
        title={"Accept Beta Application"}
      />
    </>
  );
}
