import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  CircularProgress,
  useTheme,
} from "@mui/material";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CheckBoxOutlineBlankTwoToneIcon from "@mui/icons-material/CheckBoxOutlineBlankTwoTone";

const StepStatus = ({ steps }) => {
  const theme = useTheme();
  return (
    <List dense disablePadding>
      {Object.keys(steps).length > 0 &&
        Object.entries(steps).map(([step_num, stepData]) => (
          <ListItem key={step_num} disableGutters disablePadding>
            <ListItemAvatar>
              <Avatar sx={{ background: "transparent" }}>
                {stepData.status === "starting" ||
                stepData.status === "generating_tool_use_info" ||
                stepData.status === "running_tool" ? (
                  <CircularProgress
                    sx={{
                      maxHeight: "20px",
                      maxWidth: "20px",
                      padding: "3px",
                      margin: "0px 10px 0px 0px",
                    }}
                  />
                ) : stepData.status === "finished" ? (
                  <CheckBoxTwoToneIcon
                    sx={{ color: theme.palette.success.light }}
                  />
                ) : stepData.status === "error" ? (
                  <ReportProblemIcon sx={{ color: theme.palette.error.main }} />
                ) : (
                  <CheckBoxOutlineBlankTwoToneIcon
                    sx={{ color: theme.palette.primary.light }}
                  />
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={stepData.step_description}
              secondary={stepData.status_description || ""}
            />
          </ListItem>
        ))}
    </List>
  );
};

export default StepStatus;
