import { Box, IconButton, useTheme, Typography, Tooltip } from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";
import FilePresentTwoToneIcon from "@mui/icons-material/FilePresentTwoTone";
import React from "react";
import DraggableDialog from "../../DraggableDialog";

function FileDisplay({ removeFile, files, isMessage, canRemove }) {
  const theme = useTheme();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState(null);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = (file) => {
    setDialogContent(
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            src={file.url}
            alt={file.file_name}
            sx={{
              maxWidth: "100%",
            }}
          />

          <Typography variant="caption">{file.file_name}</Typography>
        </Box>
      </>
    );
    setDialogOpen(true);
  };

  return (
    <Box
      sx={{
        display: Object.keys(files).length > 0 ? "flex" : "none",
        flexDirection: "column",
      }}
    >
      {isMessage ? (
        <Typography variant="caption">Message attachments</Typography>
      ) : (
        <Typography variant="caption">Files pending upload</Typography>
      )}
      <Box
        id="uploaded-files-box"
        sx={{
          display: "flex",
          // flexDirection: "row",
          flexWrap: "wrap",
          maxWidth: "100%",
          overflowX: "auto",
          overflowY: "hidden",
          borderRadius: 2,
          border: 1,
          mb: 1,
        }}
      >
        {files &&
          Object.values(files).map((file) => {
            return (
              <Box
                id={file.file_name + "-box"}
                key={file.file_name + "-box"}
                display="flex"
                flexDirection={"row"}
                sx={{
                  m: "3px",
                  flexWrap: "wrap",
                  textWrap: "wrap",
                  alignContent: "flex-start",
                  borderRadius: 2,
                  borderColor: theme.palette.primary.dark,
                  bgcolor: theme.palette.background.paper,
                  // maxWidth: "100px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                  }}
                >
                  <Tooltip
                    title={
                      canRemove
                        ? "Remove file"
                        : "Can't currently remove this file, try refreshing the page."
                    }
                  >
                    <IconButton
                      key={file.file_name + "-button"}
                      onClick={() => removeFile(file)}
                      sx={{
                        color: theme.palette.primary.contrastText,
                        padding: 0,
                      }}
                    >
                      <IoCloseCircleOutline />
                    </IconButton>
                  </Tooltip>
                </Box>
                {/* <Stack direction={"column"}> */}
                {file &&
                file.file_type &&
                file.file_type.startsWith("image/") ? (
                  <Tooltip title={file.file_name}>
                    <Box
                      key={file.file_name + "-img-box"}
                      component="img"
                      sx={{
                        padding: 1,
                        maxHeight: "100px",
                        maxWidth: "100px",
                        cursor: "pointer",
                      }}
                      src={file.url}
                      alt={file.file_name}
                      onClick={() => handleDialogOpen(file)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={file.file_name}>
                    <Box
                      key={file.file_name + "-file-box"}
                      sx={{
                        padding: 1,
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "200px",
                        maxHeight: "75px",
                      }}
                    >
                      <Box
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <FilePresentTwoToneIcon
                          sx={{
                            color: theme.palette.primary.light,
                          }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {file.file_name}
                        </Typography>
                      </Box>
                    </Box>
                  </Tooltip>
                )}
                <Box
                  key={file.file_name + "-progress-box"}
                  id={file.file_name + "-progress-box"}
                  sx={{
                    display:
                      file.progress && file.progress > 0 && file.progress < 100
                        ? "block"
                        : "none",
                    position: "relative",
                    textAlign: "right",
                    color: theme.palette.primary.contrastText,
                    bgcolor: theme.palette.primary.light,
                    fontFamily: "monospace",
                    fontSize: "small",
                    fontWeight: "bold",
                    width: "100%",
                    borderRadius: 1,
                    pr: "10px",
                    mr: "10px",
                    mb: "2px",
                    ml: "10px",
                    "&::after": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      borderRadius: 1,
                      top: 0,
                      left: 0,
                      bottom: 0,
                      width: `${file.progress}%`,
                      bgcolor: theme.palette.secondary.light,
                      background: `linear-gradient(to right, ${theme.palette.secondary.light} 0%, ${theme.palette.secondary.light} 100%)`,
                    },
                  }}
                >
                  {file.progress}%
                </Box>
                {/* </Stack> */}
              </Box>
            );
          })}
      </Box>
      <DraggableDialog
        open={dialogOpen}
        cancellationButtonText={"Close"}
        content={dialogContent}
        onCancel={handleDialogClose}
        title={"Attached File"}
      />
    </Box>
  );
}

export default FileDisplay;

/* <LinearProgressWithLabel
                    id={file.file_name + "-progress"}
                    key={file.file_name + "-progress"}
                    sx={{
                      width: "100%",
                      bottom: 0,
                      height: "5px",
                      ml: "12px",
                      mb: "5px",
                      value: file.progress,
                    }}
                    variant="determinate"
                    value={file.progress}
                  /> */

// function LinearProgressWithLabel(props) {
//   //   console.log("LinearProgressWithLabel props", props);
//   return (
//     <Box sx={{ display: "flex", alignItems: "center" }}>
//       <Box sx={{ width: "100%", mr: 1 }}>
//         <LinearProgress variant="determinate" {...props} />
//       </Box>
//       <Box sx={{ minWidth: 35 }}>
//         <Typography variant="body2" color="text.secondary">{`${Math.round(
//           props.value
//         )}%`}</Typography>
//       </Box>
//     </Box>
//   );
// }
