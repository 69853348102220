import { useCustomFetch } from "./customFetch";

const AUTH_API_HOST = `${window.env.REACT_APP_AUTH_API_HOST}`;

export const useAuthApi = () => {
  const { customFetch } = useCustomFetch();

  const logoutUser = async () => {
    try {
      const response = await fetch(`${AUTH_API_HOST}/logout`, {
        method: "POST",
        credentials: "include", // Ensure credentials are included
      });

      if (!response.ok) {
        // throw new Error("Logout failed");
        return false;
      }

      // Clear the access_token from local storage
      localStorage.removeItem("access_token");

      return true;
    } catch (error) {
      // throw error;
      return false;
    }
  };

  const loginUser = async (username, password) => {
    try {
      const response = await fetch(`${AUTH_API_HOST}/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ username, password }),
        credentials: "include", // Ensure credentials are included
      });

      if (!response.ok) {
        return false;
      }

      // Get the access_token from the response and set it in local storage
      const data = await response.json();
      localStorage.setItem("access_token", data.access_token);

      return true;
    } catch (error) {
      console.error("Login error:", error);
      // Don't expose the error message to the user
      return false;
    }
  };

  const oauthLogin = async (provider) => {
    try {
      const response = await customFetch(
        `${AUTH_API_HOST}/oauth/login/${provider}`,
        {
          method: "GET",
          credentials: "include", // Ensure credentials are included
        }
      );

      if (!response.ok) {
        return false;
      }

      // Parse the response to get the redirection URL
      const data = await response.json();
      const redirectUrl = data.redirect_url;

      // Redirect to the OAuth provider login page
      window.open(redirectUrl, "_self").focus(); // Open in the same tab and focus

      return true;
    } catch (error) {
      console.error("OAuth login error:", error);
      // Don't expose the error message to the user
      return false;
    }
  };

  return { loginUser, logoutUser, oauthLogin };
};
