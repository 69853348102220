import React from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  useTheme,
  Divider,
  Link,
} from "@mui/material";

import { useUserApi } from "../../api/userApi";
import DraggableDialog from "../DraggableDialog";

const AboutPage = () => {
  const theme = useTheme();
  const { betaUserSignup } = useUserApi();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState("");

  const handleBetaSignup = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get("name");
    const email = data.get("email");
    const useCase = data.get("use_case");

    try {
      const message = await betaUserSignup(name, email, useCase);
      setDialogContent(message);
      setDialogOpen(true);

      // Clear the form
      event.target.reset();
    } catch (error) {
      setDialogContent(
        <>
          Failed to sign up for beta, please contact support at{" "}
          <Link to="mailto:zipbot.ai@gmail.com">zipbot.ai@gmail.com</Link>.
        </>
      );
      setDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Container
      disableGutters
      id="about-container"
      maxWidth={false}
      sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        color: theme.palette.text.primary,
        height: "calc(100dvh - 64px)",
      }}
    >
      {/* Welcome Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          m: 4,
        }}
      >
        <Typography
          fontSize={{ xs: "1.5rem", sm: "2rem" }}
          fontWeight="bold"
          color={theme.palette.primary.light}
        >
          Welcome to ZipBot.ai!
        </Typography>
        <Typography variant="body1">
          Offering a suite of custom-built bots designed to cater to various
          organizational needs.
        </Typography>
      </Box>

      {/* Bot Information Section */}
      <Grid
        container
        spacing={4}
        sx={{
          width: "90%",
          alignSelf: "center",
        }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Box
              component="img"
              src="document-bot.png"
              alt="Regulatory Bots"
              sx={{ borderRadius: "50%", width: 64, height: 64 }}
            />
            <Box ml={2}>
              <Typography variant="h6">Regulatory Bots</Typography>
              <Typography variant="body2">
                Regulatory bots designed for entities navigating industry
                regulations. Offers access to essential regulatory documents,
                with premium options for licensed standards like ISO or IEC.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Box
              component="img"
              src="task-bot.png"
              alt="Task Bots"
              sx={{ borderRadius: "50%", width: 64, height: 64 }}
            />
            <Box ml={2}>
              <Typography variant="h6">Task Bots</Typography>
              <Typography variant="body2">
                Task bots ideal for task automation. Connects corporate software
                APIs to automate processes, offering both pre-defined and
                customizable workflows.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Box
              component="img"
              src="support-bot.png"
              alt="Q/A Bots"
              sx={{ borderRadius: "50%", width: 64, height: 64 }}
            />
            <Box ml={2}>
              <Typography variant="h6">Q/A Bots</Typography>
              <Typography variant="body2">
                Q/A bots that allow you to offload the burdensome tasks related
                to low-level user support. Tailored for organizations aiming to
                leverage their documentation through an accessible chat bot.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Box
              component="img"
              src="research-bot.png"
              alt="Research Bots"
              sx={{ borderRadius: "50%", width: 64, height: 64 }}
            />
            <Box ml={2}>
              <Typography variant="h6">Research Bots</Typography>
              <Typography variant="body2">
                Research bots that work with you and your team to explore the
                realm of collected human knowledge in pursuit of new
                discoveries.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ m: 4, bgcolor: theme.palette.primary.main }} />

      {/* Sign-Up/Login Section */}
      <Box
        sx={{
          alignSelf: "center",
          display: "flex",
          flexDirection: { sm: "row", xs: "column" },
          maxWidth: "90%",
          my: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "row",
              mr: 2,
            }}
          >
            <Box
              component="img"
              src="zippy.png"
              alt="Personal Assistant (Zippy)"
              sx={{ borderRadius: "50%", width: 64, height: 64 }}
            />
            <Box ml={2}>
              <Typography variant="h6">Personal Assistant (Zippy)</Typography>
              <Typography variant="body2">
                Zippy, your personal assistant.
                <br />
                <br /> Demonstrating various capabilities of the purpose-built
                bots, Zippy is a consumer-accessible digital assistant that
                provides a number of the features that are available in the
                specialized bots, but for individual use.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "row",
              my: 4,
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            minWidth: 250,
          }}
        >
          <Typography
            variant="body1"
            gutterBottom
            sx={{ alignSelf: "center", textAlign: "center" }}
          >
            Zippy is currently in beta for select users.
            <br />
            <br />
            Sign up with your use case today!
          </Typography>

          <form onSubmit={handleBetaSignup}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name:"
                  variant="outlined"
                  id="name"
                  name="name"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email:"
                  variant="outlined"
                  id="email"
                  name="email"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Use case:"
                  multiline
                  rows={4}
                  variant="outlined"
                  id="use_case"
                  name="use_case"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Sign Up
                </Button>
              </Grid>
            </Grid>
          </form>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
              flexDirection: "row",
              my: 4,
            }}
          >
            <Typography variant="body2">
              Already a member of the beta?
            </Typography>
            <Button
              variant="contained"
              sx={{ color: "primary", ml: 4, minWidth: "100px" }}
              // on click navigate to /login
              onClick={() => {
                window.location.href = "/login";
              }}
            >
              Log In
            </Button>
          </Box>
        </Box>
      </Box>
      <DraggableDialog
        open={dialogOpen}
        confirmationButtonText={"OK"}
        content={dialogContent}
        onConfirm={handleDialogClose}
        title={"Beta Signup"}
      />
    </Container>
  );
};

export default AboutPage;
