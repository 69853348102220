export class MessageFile {
  constructor(name, type, url, key, description, fileObject, progress = 0, uploaded=false) {
    this.file_name = name;
    this.file_type = type;
    this.url = url;
    this.key = key;
    this.file_description = description;
    this.fileObject = fileObject;
    this.progress = progress;
    this.uploaded = uploaded;
  }
}
