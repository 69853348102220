import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListTwoToneIcon from "@mui/icons-material/ListTwoTone";
import { useTheme } from "@mui/material/styles";
import { Box, Divider, Fade, TextField } from "@mui/material";
import { useConversationsApi } from "../../../api/conversationsApi";
import { ConversationContext } from "../providers/ConversationProvider";
import ConfirmationDialog from "../../ConfirmationDialog";
import { useState } from "react";
import DraggableDialog from "../../DraggableDialog";
import { useContext } from "react";
import { useEffect } from "react";
import { ConversationDetails } from "../classes/ConversationDetails";
import { useNavigate } from "react-router-dom";

export default function ConversationSettingsMenu({ conversationId }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [settingsDialogContent, setSettingsDialogContent] = useState(null);
  const [conversationDetails, setConversationDetails] = useState(null);
  const [supportedModels, setSupportedModels] = useState([]);

  const navigate = useNavigate();

  const {
    deleteConversation,
    getConversationDetails,
    saveConversationDetails,
    getSupportedModels,
  } = useConversationsApi();

  const handleClick = (event) => {
    // Do not propagate the event
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  // Delete the message
  const handleDelete = async (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpenConfirmationDialog(true);
    handleClose(event);
  };

  const onConfirmDeleteCollection = async () => {
    try {
      await deleteConversation(conversationId);

      navigate("/conversations");
    } catch (error) {
      console.error("Error deleting conversation:", error);
    }
  };

  const handleSettings = async (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    handleClose(event);

    // Get the conversation information and settings from the API
    const details = await getConversationDetails(conversationId);
    const models = await getSupportedModels();

    setSupportedModels(models);
    setConversationDetails(details);
  };

  // Update conversation summary
  const updateConversationSummary = async (value) => {
    // Update the summary in the conversation details
    const newDetails = new ConversationDetails(
      conversationDetails.id,
      value,
      conversationDetails.modelConfiguration,
      conversationDetails.systemPrompt
    );

    setConversationDetails(newDetails);
  };

  // Update conversation model
  const updateConversationModel = async (value) => {
    // Update the model in the conversation details
    let modelConfiguration = conversationDetails.modelConfiguration;
    modelConfiguration.model = value;

    const newDetails = new ConversationDetails(
      conversationDetails.id,
      conversationDetails.summary,
      modelConfiguration,
      conversationDetails.systemPrompt
    );

    setConversationDetails(newDetails);
  };

  useEffect(() => {
    if (conversationDetails) {
      setSettingsDialogContent(
        <Box sx={{ margin: 1, display: "flex", flexDirection: "column" }}>
          <TextField
            label="Conversation Title"
            value={conversationDetails.summary}
            onChange={(e) => updateConversationSummary(e.target.value)}
            sx={{ margin: 1, minWidth: "300px" }}
          />
          <Divider sx={{ margin: 1 }} />
          {/* Create a selection list of supported models, selecting the conversationDetails.modelConfiguration.model */}
          <TextField
            select
            label="Model"
            value={conversationDetails.modelConfiguration.model}
            sx={{ margin: 1, minWidth: "300px" }}
            onChange={(e) => updateConversationModel(e.target.value)}
          >
            {supportedModels.models.map((model) => (
              <MenuItem key={model} value={model}>
                {model}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      );
      setSettingsDialogOpen(true);
    }
  }, [conversationDetails]);

  const handleSettingsDialogClose = (event) => {
    event.stopPropagation();

    setSettingsDialogOpen(false);
  };

  const handleSaveSettings = async (event) => {
    event.stopPropagation();

    // Save the settings
    await saveConversationDetails(conversationDetails);

    setSettingsDialogOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Button
        variant="text"
        onClick={handleClick}
        sx={{
          color: theme.palette.primary.contrastText,
          ":hover": {
            color: theme.palette.primary.main,
            bgcolor: "transparent",
          },
          alignContent: "end",
          pr: 0,
        }}
      >
        <ListTwoToneIcon sx={{ width: "20px" }} />
      </Button>
      <Menu
        id="conversation-message-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        onClick={(e) => e.stopPropagation()}
      >
        <MenuItem onClick={handleSettings}>Conversation Settings</MenuItem>
        <Divider />
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        title={"Delete Conversation"}
        content={"Are you sure you want to delete this conversation?"}
        onConfirm={onConfirmDeleteCollection}
      />
      <DraggableDialog
        open={settingsDialogOpen}
        confirmationButtonText={"OK"}
        cancellationButtonText={"Cancel"}
        content={settingsDialogContent}
        onConfirm={handleSaveSettings}
        onCancel={handleSettingsDialogClose}
        title={"Conversation Settings"}
      />
    </Box>
  );
}
