import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Container,
  useTheme,
  ListItemButton,
  MenuItem,
  Stack,
  Box,
  Tooltip,
  Typography,
  Tabs,
  Tab,
  Link,
} from "@mui/material";
import PropTypes from "prop-types";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FilesGrid from "./FilesGrid";
import { useDocumentsApi } from "../../api/documentsApi";
import CreateCollectionPopup from "./CreateCollectionPopup";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConfirmationDialog from "../ConfirmationDialog";

const Documents = () => {
  const theme = useTheme();
  const {
    fetchDocumentCollections,
    createDocumentCollection,
    getDocumentCollectionFiles,
    getAllFiles,
    deleteDocumentCollection,
  } = useDocumentsApi();
  const [documentCollections, setDocumentCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [files, setFiles] = useState([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedTabValue, setSelectedTabValue] = useState(0);
  const [openCreateCollectionPopup, setOpenCreateCollectionPopup] =
    useState(false);

  useEffect(() => {
    // First time initialization
    populateTab(0);
  }, []);

  useEffect(() => {
    // Placeholder to fetch files for the selected collection
    // get files associated with the selected collection
  }, [selectedCollection]);

  useEffect(() => {
    populateTab(selectedTabValue);
  }, [selectedTabValue]);

  function populateTab(tabValue) {
    // Handle the tab change
    if (tabValue === 1) {
      // They are on the collections tab

      populateDocumentCollections();

      // Show them a side-by-side view of the selected collection and its files,
      // along with the available files to add to the collection

      // const response = await getDocumentCollectionFiles(selectedCollection);
    } else if (tabValue === 0) {
      // They are on the files tab
      // Show them all of the files associated with their account
      fetchAllFiles();
    }
  }

  const populateDocumentCollections = async () => {
    const response = await fetchDocumentCollections();
    setDocumentCollections(response);
  };

  const fetchAllFiles = async () => {
    // Get all of the files associated with this account
    const response = await getAllFiles();

    setFiles(response);
  };

  const createCollectionHandler = async (name) => {
    const newCollection = await createDocumentCollection(name);
    setDocumentCollections([...documentCollections, newCollection]);
  };

  const handleCreateCollection = () => {
    setOpenCreateCollectionPopup(true);
  };

  const handleSelectCollection = (collectionId) => {
    // Placeholder for selecting a document collection
    setSelectedCollection(collectionId);
  };

  const handleDeleteCollection = async (collectionId) => {
    // Show the confirmation dialog
    setOpenConfirmationDialog(true);
  };

  const handleUploadFile = async (file) => {
    // Placeholder for API call to upload a new file
  };

  const handleDeleteFile = async (fileId) => {
    // Placeholder for API call to delete a file
  };

  const handleModifyFile = async (fileId, newName, newDescription) => {
    // Placeholder for API call to modify file details
  };

  const onConfirmDeleteCollection = async () => {
    if (!selectedCollection) {
      return;
    }

    await deleteDocumentCollection(selectedCollection);
    setDocumentCollections(
      documentCollections.filter(
        (collection) => collection.id !== selectedCollection
      )
    );
    setSelectedCollection(null);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTabValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`documents-tabpanel-${index}`}
        aria-labelledby={`documents-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `documents-tab-${index}`,
      "aria-controls": `documents-tabpanel-${index}`,
    };
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <Container
      disableGutters
      id="documents-container"
      maxWidth={false}
      sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        color: theme.palette.text.primary,
        height: "calc(100dvh - 64px)",
        // bgcolor: "red",
        // justifyContent: "center",
        // alignContent: "center",
        // alignItems: "center",
        // maxWidth: "350px",
      }}
    >
      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        title={"Delete Collection"}
        content={"Are you sure you want to delete this collection?"}
        onConfirm={onConfirmDeleteCollection}
      />
      <CreateCollectionPopup
        openCreateCollectionPopup={openCreateCollectionPopup}
        setOpenCreateCollectionPopup={setOpenCreateCollectionPopup}
        createCollectionHandler={createCollectionHandler}
      />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          centered
          value={selectedTabValue}
          onChange={handleTabChange}
          aria-label="document tabs"
        >
          <Tab label="Files" {...a11yProps(0)} />
          <Tooltip title="Coming soon: Create collections of files to use in different conversations.">
            <Tab label="Collections" disabled {...a11yProps(1)} />
          </Tooltip>
        </Tabs>
      </Box>
      <CustomTabPanel value={selectedTabValue} index={1}>
        <Box
          id="collection-box"
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "3px",
            alignSelf: "flex-start",
            width: "95%",
          }}
        >
          <Button
            onClick={() => handleCreateCollection()}
            sx={{ ":hover": { bgcolor: "transparent" } }}
          >
            <Tooltip title="Create a document collection" arrow>
              <AddCircleIcon />
            </Tooltip>
          </Button>
          <List sx={{ flexGrow: 1, margin: "5px" }}>
            <TextField
              select
              variant="filled"
              label="Select a collection"
              value={selectedCollection ? selectedCollection : ""}
              onChange={(event) => handleSelectCollection(event.target.value)}
              sx={{
                margin: "5px",
                minWidth: "250px",
                maxWidth: "350px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {documentCollections.map((collection) => (
                <MenuItem key={collection.id} value={collection.id}>
                  {collection.collection_name}
                </MenuItem>
              ))}
            </TextField>
          </List>
          <Button
            onClick={() => handleDeleteCollection()}
            sx={{
              display: selectedCollection ? "block" : "none",
              ":hover": { bgcolor: "transparent" },
            }}
          >
            <Tooltip title="Delete the selected document collection" arrow>
              <DeleteForeverIcon />
            </Tooltip>
          </Button>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTabValue} index={0}>
        <Box
          id="file-container"
          sx={{
            alignSelf: "center",
            flexDirection: "column",
            maxWidth: "100%",
            alignItems: "center",
            display: "flex",
          }}
        >
          {/* Might re-add this once collections are enabled */}
          {/* <Tooltip title="Upload a file" arrow>
            <Button onClick={() => handleUploadFile()}>
              <Typography sx={{ margin: "3px" }} variant="caption">
                Upload a file
              </Typography>
              <UploadFileIcon />
            </Button>
          </Tooltip> */}
          <Box
            id="file-list-box"
            sx={{
              flexGrow: 1,
              alignContent: "center",
              display: files && files.length > 0 ? "flex" : "none",
              maxWidth: "95%",
              minWidth: "95%",
            }}
          >
            <FilesGrid files={files} requestFilesRefresh={fetchAllFiles} />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              alignContent: "center",
              display: !files || files.length <= 0 ? "flex" : "none",
            }}
          >
            <Typography variant="caption" sx={{ margin: "5px" }}>
              No files found. Upload a file in a{" "}
              <Link href="/conversations">conversation</Link> to get started
            </Typography>
          </Box>
        </Box>
      </CustomTabPanel>
    </Container>
  );
};

export default Documents;
