import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import ConversationList from "./ConversationList";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { Box, Drawer } from "@mui/material";
import { useSwipeable } from "react-swipeable";
import { ConversationContext } from "../providers/ConversationProvider";

export default function DrawerComponent({ drawerWidth }) {
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const rightSwipeHandlers = useSwipeable({
    onSwipedRight: () => {
      setMobileOpen(true);
    },
  });

  const leftSwipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      setMobileOpen(false);
    },
  });

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <Box sx={{ bgcolor: "red" }}>
      <IconButton
        aria-label="open close drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          width: "35px",
          height: "35px",
          alignContent: "start",
          ml: "5px",
          mt: "15px",
          display: { sm: "none" },
          position: "absolute",
          color: theme.palette.primary.main,
          zIndex: 101,
        }}
      >
        <ChevronRightIcon />
      </IconButton>

      <Box
        id="drawer-box"
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          display: "flex",
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          {...leftSwipeHandlers}
          id="mobile-drawer"
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            position: "absolute",
            top: "55px",
            display: { xs: "block", sm: "none" },
            width: drawerWidth,
            flexShrink: 0,
            height: "calc(100dvh - 55px)",
            [`& .MuiDrawer-paper`]: {
              position: "absolute",
              width: drawerWidth,
              boxSizing: "border-box",
              height: "calc(100dvh - 55px)",
            },
          }}
        >
          <ConversationList handleDrawerClose={handleDrawerClose} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            flexGrow: 1,
            overflow: "auto",
            width: drawerWidth,
            height: "calc(100dvh - 64px)",
            [`& .MuiDrawer-paper`]: {
              position: "relative",
              width: drawerWidth,
              boxSizing: "border-box",
              height: "100%",
            },
          }}
          open
        >
          <ConversationList />
        </Drawer>
      </Box>
    </Box>
  );
}
