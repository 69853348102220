import * as React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import FilePresentIcon from "@mui/icons-material/FilePresent";

function FileSelectionDialog(props) {
  const { onClose, open, files } = props;
  const [checkedFiles, setCheckedFiles] = React.useState([]);

  const handleToggle = (file) => () => {
    const currentIndex = checkedFiles.indexOf(file);
    const newChecked = [...checkedFiles];

    if (currentIndex === -1) {
      newChecked.push(file);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedFiles(newChecked);
  };

  const handleClose = () => {
    onClose(checkedFiles);
    setCheckedFiles([]);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "8px",
          margin: "8px",
          overflowY: "auto",
        }}
      >
        <DialogTitle>Select Files</DialogTitle>
        <Box sx={{ overflowY: "auto" }}>
          <FormGroup
            id={"file-list"}
            key={"file-list"}
            sx={{
              padding: "8px",
              margin: "8px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {files.map((file) => (
              <FormControlLabel
                key={"file-" + file.key}
                id={"file-" + file.key}
                control={
                  <Checkbox
                    id={"file-checkbox-" + file.key}
                    key={"file-checkbox-" + file.key}
                    sx={{ pb: 0, pt: 0, pl: 2, pr: 2 }}
                    onChange={handleToggle(file)}
                    checked={checkedFiles.includes(file)}
                  />
                }
                label={file.file_name}
              />
            ))}
          </FormGroup>
        </Box>
        <Button
          id={"associate-files"}
          key={"associate-files"}
          onClick={handleClose}
          variant="contained"
          color="primary"
        >
          Associate Files
        </Button>
      </Box>
    </Dialog>
  );
}

FileSelectionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  files: PropTypes.array.isRequired,
};

export default FileSelectionDialog;
