import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import {
  Button,
  Fade,
  List,
  ListItem,
  Menu,
  MenuItem,
  Popover,
  useTheme,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ErrorIcon from "@mui/icons-material/Error";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import TimerIcon from "@mui/icons-material/Timer";
import AssignmentIcon from "@mui/icons-material/Assignment";

import { useDocumentsApi } from "../../api/documentsApi";
import ConfirmationDialog from "../ConfirmationDialog";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  //   {
  //     id: "id",
  //     numeric: false,
  //     disablePadding: true,
  //     label: "ID",
  //   },
  {
    id: "file_name",
    align: "left",
    disablePadding: true,
    label: "File Name",
    minWidth: 150,
  },
  {
    id: "file_type",
    align: "left",
    disablePadding: false,
    label: "File Type",
    minWidth: 150,
  },
  {
    id: "tasks",
    align: "right",
    disablePadding: false,
    label: "Tasks",
    minWidth: 75,
  },
  // {
  //   id: "url",
  //   align: "left",
  //   disablePadding: false,
  //   label: "Url",

  // },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const theme = useTheme();

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all files",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography
                sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
                variant="body2"
              >
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    files,
    requestFilesRefresh,
    selectedFilters,
    setSelectedFilters,
    selectedFiles,
  } = props;
  const [filterVisible, setFilterVisible] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const fileTypes = [...new Set(files.map((file) => file.file_type))];
  const { deleteFiles } = useDocumentsApi();
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);

  const handleFilterChange = (event) => {
    const { name, checked } = event.target;
    setSelectedFilters((current) =>
      checked ? [...current, name] : current.filter((type) => type !== name)
    );
  };

  const showFilterList = (e) => {
    setAnchorEl(e.currentTarget);
    setFilterVisible(!filterVisible);
  };

  const onCloseMenu = () => {
    setFilterVisible(false);
  };

  const handleDeleteFiles = async (e) => {
    await deleteFiles(selectedFiles);
    // Refresh the files list by removing these files from the files list
    requestFilesRefresh();
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Files
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete selected files">
          <IconButton
            onClick={() => {
              setOpenConfirmationDialog(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter files">
          <IconButton onClick={showFilterList}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}

      <Menu
        id="file-filter-menu"
        anchorEl={anchorEl}
        open={filterVisible}
        onClose={onCloseMenu}
        TransitionComponent={Fade}
      >
        {fileTypes.map((type) => (
          <MenuItem key={type}>
            <input
              type="checkbox"
              id={type}
              name={type}
              checked={selectedFilters.includes(type)}
              onChange={handleFilterChange}
            />
            <label htmlFor={type}>{type}</label>
          </MenuItem>
        ))}
      </Menu>
      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        title={"Delete Files"}
        content={
          "Are you sure you want to delete the selected files?  The operation is permanent."
        }
        onConfirm={handleDeleteFiles}
      />
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  files: PropTypes.array.isRequired,
  requestFilesRefresh: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  selectedFiles: PropTypes.array.isRequired,
};

export default function FilesGrid({ files, requestFilesRefresh }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const [filteredFiles, setFilteredFiles] = React.useState([]);

  const theme = useTheme();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const { getDownloadUrl, runDocumentTask } = useDocumentsApi();

  async function downloadFile(ui_key) {
    const downloadUrl = await getDownloadUrl(ui_key);

    window.open(downloadUrl, "_blank");
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteredFiles.map((n) => n.ui_key);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredFiles.length) : 0;

  const visibleRows = React.useMemo(() => {
    let filteredFiles = files;
    if (selectedFilters.length > 0) {
      // If the selectedFilters array is not null
      filteredFiles = files.filter((file) =>
        selectedFilters.includes(file.file_type)
      );
    }

    setFilteredFiles(filteredFiles);

    return stableSort(filteredFiles, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [order, orderBy, page, rowsPerPage, files, selectedFilters]);

  const [taskAnchorEl, setTaskAnchorEl] = React.useState(null);
  const taskPopoverOpen = Boolean(taskAnchorEl);

  const [selectedUiKey, setSelectedUiKey] = React.useState("");

  const handleTasksClick = (event, ui_key) => {
    setSelectedUiKey(ui_key);

    event.stopPropagation();
    setTaskAnchorEl(event.currentTarget);
  };

  const handleTaskPopoverClose = (event) => {
    setSelectedUiKey("");

    event.stopPropagation();
    setTaskAnchorEl(null);
  };

  const handleStartTaskClick = async (ui_key, task_name) => {
    await runDocumentTask(ui_key, task_name);

    requestFilesRefresh();
  };

  return (
    <React.Fragment>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            files={files}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            selectedFiles={selected}
            requestFilesRefresh={requestFilesRefresh}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: "450px" }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredFiles.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.ui_key);
                  const labelId = `file-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.ui_key)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.ui_key}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="left"
                        sx={{
                          maxWidth: "300px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Tooltip title="Download file">
                          <Button
                            id={row.ui_key + "-download-button"}
                            sx={{
                              padding: 0,
                              margin: 0,
                              ":hover": { bgcolor: "transparent" },
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              downloadFile(row.ui_key);
                            }}
                          >
                            <DownloadIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title={row.file_name}>{row.file_name}</Tooltip>
                      </TableCell>
                      <TableCell align="left">{row.file_type}</TableCell>
                      <TableCell align="right">
                        {Object.keys(row.tasks).length > 0 && (
                          <Box onClick={(e) => handleTasksClick(e, row.ui_key)}>
                            {row.has_failed_tasks ? (
                              <Tooltip title="There are failed tasks. Click to open.">
                                <ReportProblemIcon
                                  sx={{ color: theme.palette.error.main }}
                                />
                              </Tooltip>
                            ) : row.has_running_tasks ? (
                              <Tooltip title="There are running tasks. Click to open.">
                                <TimelapseIcon
                                  sx={{ color: theme.palette.info.main }}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Click to open tasks.">
                                <AssignmentIcon
                                  sx={{ color: theme.palette.primary.light }}
                                />
                              </Tooltip>
                            )}
                            {row.ui_key === selectedUiKey && (
                              <Popover
                                id={row.ui_key + "-tasks-popover"}
                                open={taskPopoverOpen}
                                anchorEl={taskAnchorEl}
                                onClose={handleTaskPopoverClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <List onClick={(e) => e.stopPropagation()}>
                                  {/* row.tasks is a dictionary, iterate over the keys */}
                                  {Object.keys(row.tasks).map(
                                    (taskKey, index) => {
                                      const task = row.tasks[taskKey];
                                      return (
                                        <ListItem key={index}>
                                          {task.last_run ? (
                                            task.last_run.state ===
                                            "FAILURE" ? (
                                              <>
                                                <Tooltip title="Restart task">
                                                  <RestartAltIcon
                                                    onClick={(e) =>
                                                      handleStartTaskClick(
                                                        row.ui_key,
                                                        taskKey
                                                      )
                                                    }
                                                    sx={{
                                                      marginRight: "8px",
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </Tooltip>
                                                <Tooltip
                                                  title={
                                                    task.last_run.description
                                                  }
                                                >
                                                  <ErrorIcon
                                                    sx={{
                                                      color:
                                                        theme.palette.error
                                                          .main,
                                                      marginRight: "8px",
                                                    }}
                                                  />
                                                </Tooltip>
                                              </>
                                            ) : task.last_run.state ===
                                                "PROGRESS" ||
                                              task.last_run.state ===
                                                "STARTED" ? (
                                              <Tooltip
                                                title={
                                                  task.last_run.description
                                                }
                                              >
                                                <TimelapseIcon
                                                  sx={{
                                                    color:
                                                      theme.palette.info.light,
                                                    marginRight: "8px",
                                                  }}
                                                />
                                              </Tooltip>
                                            ) : task.last_run.state ===
                                              "QUEUED" ? (
                                              <Tooltip
                                                title={
                                                  task.last_run.description
                                                }
                                              >
                                                <TimerIcon
                                                  sx={{
                                                    color:
                                                      theme.palette.info.main,
                                                    marginRight: "8px",
                                                  }}
                                                />
                                              </Tooltip>
                                            ) : (
                                              task.last_run.state ===
                                                "SUCCESS" && (
                                                <Tooltip
                                                  title={
                                                    task.last_run.description
                                                  }
                                                >
                                                  <CheckCircleIcon
                                                    sx={{
                                                      color:
                                                        theme.palette.success
                                                          .main,
                                                      marginRight: "8px",
                                                    }}
                                                  />
                                                </Tooltip>
                                              )
                                            )
                                          ) : (
                                            <Tooltip title="Run task">
                                              <NotStartedIcon
                                                onClick={(e) =>
                                                  handleStartTaskClick(
                                                    row.ui_key,
                                                    taskKey
                                                  )
                                                }
                                                sx={{
                                                  marginRight: "8px",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </Tooltip>
                                          )}
                                          {task.friendly_name}
                                        </ListItem>
                                      );
                                    }
                                  )}
                                </List>
                              </Popover>
                            )}
                          </Box>
                        )}
                      </TableCell>

                      {/* <TableCell align="left"><Button><DownloadIcon /></Button></TableCell> */}
                      {/* <TableCell align="right">
                        {row.ingestion_complete ? "Yes" : "No"}
                      </TableCell> */}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredFiles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
      </Box>
    </React.Fragment>
  );
}
