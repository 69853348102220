const useTime = () => {
  const getLocalTime = (originalDateTime) => {
    const timezoneOffset = new Date().getTimezoneOffset() * 60000; // Convert minutes to milliseconds

    // conversation.record_modified is a UTC timestamp, so convert it to local time
    const recordModifiedUTC = new Date(originalDateTime);
    const recordModifiedLocal = new Date(
      recordModifiedUTC.getTime() - timezoneOffset
    );
    return recordModifiedLocal;
  };

  return { getLocalTime };
};

export default useTime;
