import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuthApi } from "../api/authApi";
import { useUserApi } from "../api/userApi";
import { CircularProgress } from "@mui/material";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { fetchUserDetails } = useUserApi();
  const { logoutUser, loginUser } = useAuthApi();

  const [isAdmin, setIsAdmin] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    isLoggedIn().finally(() => setLoading(false)); // Update loading state after check
  }, [loggingOut]);

  const login = async (email, password) => {
    const success = await loginUser(email, password);

    // If successful, set the user details and set logged in to true
    if (success) {
      await isLoggedIn();
      return true;
    } else {
      setLoggedIn(false);
      setUserDetails(null);
      setLoggingOut(true);
      setIsAdmin(false);
      return false;
    }
  };

  const isLoggedIn = async () => {
    try {
      const details = await fetchUserDetails();
      if (details) {
        setIsAdmin(details.is_admin);
        setUserDetails(details);
        setLoggedIn(true);
        return true;
      } else {
        setUserDetails(null);
        setLoggedIn(false);
        setLoggingOut(true);
        setIsAdmin(false);
      }
    } catch (error) {
      setUserDetails(null);
      setLoggedIn(false);
      setLoggingOut(true);
      setIsAdmin(false);
    }

    return false;
  };

  const logout = async () => {
    await logoutUser();
    setUserDetails(null);
    setLoggedIn(false);
    setLoggingOut(true);
    setIsAdmin(false);
  };

  const value = {
    loggedIn,
    userDetails,
    isLoggedIn,
    setLoggedIn,
    login,
    logout,
    setUserDetails,
    isAdmin,
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
