import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

const CreateCollectionPopup = ({
  setOpenCreateCollectionPopup,
  openCreateCollectionPopup,
  createCollectionHandler,
}) => {
  const [collectionName, setCollectionName] = useState("");
  // Create a ref for the collection name input field
  const collectionNameInputRef = React.createRef();

  useEffect(() => {
    if (openCreateCollectionPopup) {
      setTimeout(() => {
        if (collectionNameInputRef.current) {
          collectionNameInputRef.current.focus();
        }
      }, 100); // Adjust the delay as needed
    }
  }, []);

  const handleClose = () => {
    setOpenCreateCollectionPopup(false);
  };

  const handleSubmit = () => {
    // Perform the necessary actions to create the document collection

    createCollectionHandler(collectionName);

    setCollectionName("");

    // Close the popup
    handleClose();
  };

  return (
    <Box>
      <Dialog open={openCreateCollectionPopup} onClose={handleClose}>
        <DialogTitle>Create New Collection</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            inputRef={collectionNameInputRef}
            margin="dense"
            label="Collection Name"
            type="text"
            fullWidth
            value={collectionName}
            onChange={(e) => setCollectionName(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
                e.preventDefault(); // Prevent the default action to stop form submission
              }
            }}            
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateCollectionPopup;
