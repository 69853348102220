import { useEffect, useRef, useState } from "react";
import { useCustomFetch } from "./customFetch";

import { useTokens } from "./tokens";
import { ConversationDetails } from "../components/conversations/classes/ConversationDetails";
import { set } from "lodash";

const CONVERSATIONS_API_HOST = `${window.env.REACT_APP_CONVERSATIONS_API_HOST}`;
const CONVERSATIONS_API_WS_HOST = `${window.env.REACT_APP_CONVERSATIONS_API_WS_HOST}`;

export const useConversationsApi = () => {
  const { customFetch } = useCustomFetch();
  const { checkAccessToken } = useTokens();

  const createConversation = async () => {
    try {
      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/conversations`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      // response contains the new conversation id
      if (!response.ok) {
        throw new Error("Failed to create conversation");
      }

      const conversation = await response.json();

      return conversation.conversation_id;
    } catch (error) {
      console.error("Error creating conversation:", error);
      throw error;
    }
  };

  const getConversations = async () => {
    try {
      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/conversations`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (!response.ok) {
        // throw new Error("Failed to fetch conversations");
        return null;
      }

      const conversations = await response.json();

      return conversations;
    } catch (error) {
      console.error("Error fetching conversations:", error);
      // throw error;
      return null;
    }
  };

  const getConversationDetails = async (conversationId) => {
    try {
      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/conversations/${conversationId}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (!response.ok) {
        // throw new Error("Failed to fetch conversation details");
        return null;
      }

      const responseJson = await response.json();

      const conversationDetails = new ConversationDetails(
        responseJson.id,
        responseJson.conversation_summary,
        responseJson.model_configuration,
        responseJson.system_prompt
      );

      return conversationDetails;
    } catch (error) {
      console.error("Error fetching conversation details:", error);
      return null;
    }
  };

  const saveConversationDetails = async (conversationDetails) => {
    try {
      const bodyJson = JSON.stringify({
        conversation_summary: conversationDetails.summary,
        model_configuration: conversationDetails.modelConfiguration,
        system_prompt: conversationDetails.systemPrompt,
      });

      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/conversations/${conversationDetails.id}`,
        {
          method: "PUT",
          credentials: "include",
          body: bodyJson,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save conversation details");
      }
    } catch (error) {
      console.error("Error saving conversation details:", error);
      throw error;
    }
  };

  const getSupportedModels = async () => {
    try {
      const response = await customFetch(`${CONVERSATIONS_API_HOST}/models`, {
        method: "GET",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch supported models");
      }

      const models = await response.json();

      return models;
    } catch (error) {
      console.error("Error fetching supported models:", error);
      throw error;
    }
  };

  const getConversationMessages = async (conversationId) => {
    try {
      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/${conversationId}/messages`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (!response.ok) {
        // throw new Error("Failed to fetch messages");
        if (response.status === 404) {
          console.error("Conversation not found");
        }
        if (response.status === 401) {
          console.error("Unauthorized to fetch messages");
        }
        throw new Error("Failed to fetch messages");
      }

      const messages = await response.json();

      return messages;
    } catch (error) {
      // If the error contains `404`, the conversation does not exist, just return null
      if (error.message.includes("404")) {
        console.error("Conversation not found");
        return null;
      }

      console.error("Error fetching messages:", error);
      return null;
    }
  };

  async function query(
    conversationId,
    query,
    conversationAgents,
    temporaryUserMessageId,
    temporaryAiMessageId,
    associatedFileDetails,
    setAccumulatedBytes,
    onStreamComplete,
    streamStarted
  ) {
    try {
      let apiEp = `${CONVERSATIONS_API_HOST}/query`;
      if (
        conversationId !== undefined &&
        conversationId !== null &&
        conversationId !== "new"
      ) {
        apiEp = `${CONVERSATIONS_API_HOST}/${conversationId}/query`;
      }

      const bodyJson = JSON.stringify({
        query: query,
        associatedFileDetails: associatedFileDetails,
        temporaryUserMessageId: temporaryUserMessageId,
        temporaryAiMessageId: temporaryAiMessageId,
        // Get the IDs of the agents in the conversation
        agents: conversationAgents.map((agent) => agent.id),
      });

      const response = await customFetch(apiEp, {
        method: "POST",
        credentials: "include",
        body: bodyJson,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to send message");
      }

      const reader = response.body.getReader();
      let accumulatedBytes = new Uint8Array(0);

      let hasStreamStarted = false;
      try {
        while (true) {
          const { done, value } = await reader.read();

          if (!hasStreamStarted) {
            streamStarted();
            hasStreamStarted = true;
          }

          if (done) {
            onStreamComplete(conversationId);
            break;
          }

          // Add the incoming bytes to the accumulated bytes
          let tmp = new Uint8Array(accumulatedBytes.length + value.length);
          tmp.set(accumulatedBytes, 0);
          tmp.set(value, accumulatedBytes.length);
          accumulatedBytes = tmp;

          setAccumulatedBytes(accumulatedBytes);
        }
      } catch (error) {
        console.error("Failed parsing data, moving to the next token:", error);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      throw error;
    }
  }

  // Delete a conversation
  const deleteConversation = async (conversationId) => {
    try {
      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/${conversationId}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete conversation");
      }

      return response;
    } catch (error) {
      console.error("Error deleting conversation:", error);
      throw error;
    }
  };

  // Delete a message
  const deleteMessage = async (conversationId, messageId) => {
    try {
      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/${conversationId}/messages/${messageId}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete message");
      }

      return response;
    } catch (error) {
      console.error("Error deleting message:", error);
      throw error;
    }
  };

  async function setupWebSocket(
    onWebSocketOpen,
    onMessageReceived,
    onWebSocketClose
  ) {
    const authToken = await checkAccessToken();

    const ws = new WebSocket(
      `${CONVERSATIONS_API_WS_HOST}/ws?access_token=${authToken}`
    );

    ws.onopen = (ev) => {
      onWebSocketOpen(ws, ev);
    };

    ws.onmessage = (event) => {
      onMessageReceived(event.data);
    };

    ws.onclose = (ev) => {
      onWebSocketClose(ev);
    };

    ws.onerror = (error) => {
      // Placeholder for handling errors
      console.error("WebSocket error:", error);
    };

    return ws;
  }

  const removeFileFromMessageAndConversation = async (
    conversationId,
    messageId,
    uiKey
  ) => {
    // endpoint looks like: "/{conversation_id}/messages/{message_id}?ui_key={ui_key}"
    try {
      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/${conversationId}/messages/${messageId}/files/ui_key=${uiKey}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to remove file from conversation");
      }

      return response;
    } catch (error) {
      console.error("Error removing file from conversation:", error);
      throw error;
    }
  };

  const getAvailableAgentCapabilities = async () => {
    try {
      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/capabilities`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch available agent capabilities");
      }

      const capabilities = await response.json();

      return capabilities;
    } catch (error) {
      console.error("Error fetching available agent capabilities:", error);
      throw error;
    }
  };

  const getAgents = async () => {
    try {
      const response = await customFetch(`${CONVERSATIONS_API_HOST}/agents`, {
        method: "GET",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch agents");
      }

      const agents = await response.json();

      return agents;
    } catch (error) {
      console.error("Error fetching agents:", error);
      throw error;
    }
  };

  const getConversationAgents = async (conversationId) => {
    try {
      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/conversations/${conversationId}/agents`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch conversation agents");
        return [];
      }

      const agents = await response.json();

      return agents;
    } catch (error) {
      console.error("Error fetching conversation agents:", error);
      throw error;
    }
  };

  const addConversationAgent = async (conversationId, agentId) => {
    try {
      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/conversations/${conversationId}/agents/${agentId}`,
        {
          method: "PUT",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add agent to conversation");
      }

      return response;
    } catch (error) {
      console.error("Error adding agent to conversation:", error);

      throw error;
    }
  };

  const removeConversationAgent = async (conversationId, agentId) => {
    try {
      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/conversations/${conversationId}/agents/${agentId}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to remove agent from conversation");
      }

      return response;
    } catch (error) {
      console.error("Error removing agent from conversation:", error);

      throw error;
    }
  };

  const createAgent = async (
    agentName,
    agentDescription,
    agentSystemPrompt,
    agentRole,
    agentCapabilities
  ) => {
    const agent = {
      agent_name: agentName,
      agent_description: agentDescription,
      agent_system_prompt: agentSystemPrompt,
      agent_role: agentRole,
      agent_capabilities: agentCapabilities,
    };

    try {
      const response = await customFetch(`${CONVERSATIONS_API_HOST}/agents`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(agent),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to create agent");
      }

      const createdAgent = await response.json();

      return createdAgent;
    } catch (error) {
      console.error("Error creating agent:", error);
      throw error;
    }
  };

  const deleteAgent = async (agentId) => {
    try {
      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/agents/${agentId}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete agent");
      }

      return response;
    } catch (error) {
      console.error("Error deleting agent:", error);
      throw error;
    }
  };

  const updateAgent = async (
    agentId,
    agentName,
    agentDescription,
    agentSystemPrompt,
    agentRole,
    agentCapabilities
  ) => {
    const agent = {
      agent_name: agentName,
      agent_description: agentDescription,
      agent_system_prompt: agentSystemPrompt,
      agent_role: agentRole,
      agent_capabilities: agentCapabilities,
    };

    try {
      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/agents/${agentId}`,
        {
          method: "PUT",
          credentials: "include",
          body: JSON.stringify(agent),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update agent");
      }

      return response;
    } catch (error) {
      console.error("Error updating agent:", error);
      throw error;
    }
  };

  const getOrchestrationConfiguration = async () => {
    try {
      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/orchestration_configuration`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch orchestration configuration");
      }

      const settings = await response.json();

      return settings;
    } catch (error) {
      console.error("Error fetching orchestration configuration:", error);
      throw error;
    }
  };

  const updateOrchestrationConfiguration = async (configuration) => {
    try {
      const response = await customFetch(
        `${CONVERSATIONS_API_HOST}/orchestration_configuration`,
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(configuration),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save orchestration configuration");
      }

      return response;
    } catch (error) {
      console.error("Error saving orchestration configuration:", error);
      throw error;
    }
  };

  return {
    getConversations,
    getConversationDetails,
    getSupportedModels,
    saveConversationDetails,
    getConversationMessages,
    query,
    deleteConversation,
    deleteMessage,
    setupWebSocket,
    createConversation,
    removeFileFromMessageAndConversation,
    getAvailableAgentCapabilities,
    getAgents,
    getConversationAgents,
    addConversationAgent,
    removeConversationAgent,
    createAgent,
    deleteAgent,
    updateAgent,
    getOrchestrationConfiguration,
    updateOrchestrationConfiguration,
  };
};
