import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function Login() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBackdropOpen(true); // Show the backdrop
    const data = new FormData(event.currentTarget);
    const response = await login(data.get("email"), data.get("password"));
    setBackdropOpen(false); // Hide the backdrop after login attempt
    if (response) {
      const rememberMe = data.get("remember");

      if (rememberMe) {
        const userEmail = data.get("email");
        localStorage.setItem("email", userEmail);
      } else {
        localStorage.removeItem("email");
      }

      navigate("/conversations");
    } else {
      setError("Invalid email or password.");
    }
  };

  React.useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      document.getElementById("email").value = email;
    }
  }, []);

  return (
    <Container
      component="main"
      disableGutters
      id="login-container"
      maxWidth={false}
      sx={{
        // bgcolor: "rosybrown",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        pl: 5,
        pr: 5,
        height: "100dvh",
      }}
    >
      <Backdrop
        open={backdropOpen}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        id="login-box"
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          {/* Show an error if the login fails */}

          {error && error.length > 0 && (
            <Typography variant="subtitle1" color="error" textAlign={"center"}>
              {error}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
    </Container>
  );
}
