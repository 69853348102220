import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDropzone } from "react-dropzone";
import { Avatar, Box, Typography } from "@mui/material";

export default function UploadProfilePhoto({
  userAvatar,
  setUserAvatar,
  profilePhotoOpen,
  setProfilePhotoOpen,
  setUserAvatarFile
}) {
  const [isDragActive, setIsDragActive] = React.useState(false);
  const [droppedFile, setDroppedFile] = React.useState("");

  const {
    getRootProps,
    getInputProps,
    open: openFileSelectionDialog,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Process only the first file
      if (acceptedFiles.length > 0) {
        addDroppedFile(acceptedFiles[0]);
        setUserAvatarFile(acceptedFiles[0]);
      }

      setIsDragActive(false);
    },
    onDragEnter: () => setIsDragActive(true),
    onDragOver: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
    multiple: false,
    acceptedFiles: "image/vnd.microsoft.icon,image/png,image/jpeg",
  });

  async function addDroppedFile(file) {
    let imgSrc = null;
    if (file.type.startsWith("image/")) {
      imgSrc = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    }

    setDroppedFile(imgSrc);
  }

  const handleClosed = () => {
    setDroppedFile("");
    setProfilePhotoOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={profilePhotoOpen}
        onClose={handleClosed}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            // Handle uploading the file here
            if (droppedFile) {
              setUserAvatar(droppedFile);
            }

            handleClosed();
          },
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DialogTitle sx={{ alignSelf: "center" }}>Upload an Avatar</DialogTitle>
        <DialogContent>
          <Box
            {...getRootProps()}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Avatar
              alt="User Avatar"
              src={droppedFile ? droppedFile : userAvatar}
              sx={{
                width: 128,
                height: 128,
                bgcolor: "primary",
                margin: 2,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                openFileSelectionDialog();
              }}
            />
            <input {...getInputProps()} />
          </Box>
          <Typography variant="caption">
            Drag and drop or click to select
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosed}>Cancel</Button>
          <Button type="submit" disabled={!droppedFile}>
            Save Avatar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
