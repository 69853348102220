import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { Box, List, ListItemText, Tooltip } from "@mui/material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function AgentCard({
  agent,
  onEditAgent,
  onDeleteAgent,
  availableCapabilities,
  informationText,
}) {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getCapabilityByName = (name) => {
    const capability = availableCapabilities.find((item) => item.name === name);
    if (!capability) {
      return {
        name: "Unknown",
        description: "Unknown capability",
      };
    }
    return capability;
  };

  const getRandomAgentImage = () => {
    const images = ["agent1.png", "agent2.png", "agent3.png", "agent4.png"];
    const randomIndex = Math.floor(Math.random() * images.length);
    return `/agents/${images[randomIndex]}`;
  };

  return (
    <Box sx={{ width: 300, p: 1, m: 2 }}>
      <Card>
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: theme.palette.primary.main }}
              aria-label="agent-name"
              alt={agent.name}
            />
          }
          //   action={
          //     <IconButton aria-label="settings">
          //       <MoreVertIcon />
          //     </IconButton>
          //   }
          title={agent.name}
        />
        <CardMedia
          component="img"
          height="150"
          image={agent.image_url || getRandomAgentImage()}
          alt={agent.name}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {agent.description}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          {/* If the onEditAgent is not null or undefined, show the edit button */}
          {onEditAgent && (
            <IconButton
              aria-label="edit agent"
              onClick={() => onEditAgent(agent)}
            >
              <Tooltip title="Edit agent">
                <EditIcon />
              </Tooltip>
            </IconButton>
          )}

          {onDeleteAgent && (
            <IconButton
              aria-label="delete agent"
              onClick={() => onDeleteAgent(agent)}
            >
              <Tooltip title="Delete agent">
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          )}
          {/* <IconButton aria-label="share">
            <ShareIcon />
          </IconButton> */}
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show agent details"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {informationText && (
              <>
                <Typography
                  paragraph
                  variant="h6"
                  sx={{ p: 0, m: 0, color: theme.palette.primary.main }}
                >
                  Additional Information
                </Typography>
                <Typography paragraph variant="body">
                  {informationText}
                </Typography>
              </>
            )}
            <Typography
              paragraph
              variant="h6"
              sx={{ p: 0, m: 0, color: theme.palette.primary.main }}
            >
              Capabilities
            </Typography>
            <List>
              {agent.capabilities.map((capability) => (
                <ListItemText
                  key={capability}
                  primary={getCapabilityByName(capability).display_name}
                  secondary={getCapabilityByName(capability).description}
                />
              ))}
            </List>
            <Typography
              paragraph
              variant="h6"
              sx={{
                p: 0,
                m: 0,
                color: theme.palette.primary.main,
              }}
            >
              System Prompt:
            </Typography>
            <Typography paragraph sx={{ fontStyle: "italic" }}>
              {agent.system_prompt}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  );
}
