import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useContext } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Divider from "@mui/material/Divider";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import { FaUserLarge } from "react-icons/fa6";
import { GrKey } from "react-icons/gr";
import { AuthContext } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

function AppMenu({ mode, toggleColorMode, children }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const authContext = useContext(AuthContext);

  const theme = useTheme();

  const navigate = useNavigate();

  const pages = getPages();
  const settings = getAccountSettings();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handlePageChange = async (route) => {
    navigate(route);
    handleCloseNavMenu();
    handleCloseUserMenu();
  };

  function getPages() {
    let pages = [];

    if (authContext.loggedIn) {
      pages.push(
        <MenuItem
          key="Conversations"
          onClick={() => handlePageChange("/conversations")}
        >
          <Typography textAlign="center">Conversations</Typography>
        </MenuItem>
      );

      pages.push(
        <MenuItem key="Agents" onClick={() => handlePageChange("/agents")}>
          <Typography textAlign="center">Agents</Typography>
        </MenuItem>
      );

      pages.push(
        <MenuItem
          key="Documents"
          onClick={() => handlePageChange("/documents")}
        >
          <Typography textAlign="center">Documents</Typography>
        </MenuItem>
      );

      pages.push(
        <MenuItem
          key="Integrations"
          onClick={() => handlePageChange("/integrations")}
        >
          <Typography textAlign="center">Integrations</Typography>
        </MenuItem>
      );
    }

    pages.push(
      <MenuItem key="About" onClick={() => handlePageChange("/about")}>
        <Typography textAlign="center">About</Typography>
      </MenuItem>
    );

    return pages;
  }

  function getAccountSettings() {
    let settings = [];

    if (!authContext.loggedIn) {
      settings.push(
        <MenuItem key="Login" onClick={() => handlePageChange("/login")}>
          <Stack direction="row" spacing={2} alignItems="center">
            <GrKey />
            <Typography textAlign="center">Login</Typography>
          </Stack>
        </MenuItem>
      );
    }

    if (authContext.loggedIn) {
      settings.push(
        <MenuItem key="profile" onClick={() => handlePageChange("/profile")}>
          <Stack direction="row" spacing={2} alignItems="center">
            <AccountBoxOutlinedIcon />
            <Typography textAlign="center">Profile</Typography>
          </Stack>
        </MenuItem>
      );

      if (authContext.isAdmin) {
        settings.push(
          <MenuItem
            key="administration"
            onClick={() => handlePageChange("/administration")}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <LockOutlinedIcon />
              <Typography>Administration</Typography>
            </Stack>
          </MenuItem>
        );
      }

      settings.push(<Divider key="logoutDivider" />);

      settings.push(
        <MenuItem key="logout" onClick={() => handlePageChange("/logout")}>
          <Stack direction="row" spacing={2} alignItems="center">
            <LogoutOutlinedIcon />
            <Typography>Logout</Typography>
          </Stack>
        </MenuItem>
      );
    }

    // Add a separator between the account settings and the color mode settings
    settings.push(<Divider key="accountDivider" />);

    settings.push(
      <MenuItem key="Mode" sx={{ justifyContent: "end" }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography>Mode</Typography>
          <ToggleButtonGroup
            color="primary"
            exclusive
            onChange={toggleColorMode}
            aria-label="Platform"
          >
            <ToggleButton
              key="lightMode"
              selected={mode === "light"}
              value="light"
              onClick={() => handleCloseUserMenu()}
            >
              <MdLightMode />
            </ToggleButton>
            <ToggleButton
              key="darkMode"
              selected={mode === "dark"}
              value="dark"
              onClick={() => handleCloseUserMenu()}
            >
              <MdDarkMode />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </MenuItem>
    );

    return settings;
  }

  return (
    <Container
      component="main"
      key="main-appbar-container"
      maxWidth={false}
      sx={{
        p: 0,
        m: 0,
        // backgroundColor:"greenyellow"
      }}
    >
      <Box
        sx={{
          p: 0,
          m: 0,
          // backgroundColor:"black"
        }}
      >
        <AppBar
          position="fixed"
          style={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          sx={{
            p: 0,
            m: 0,
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
          }}
        >
          <Toolbar sx={{ flexGrow: 1 }}>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "flex",
                  md: "none",
                },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => page)}
              </Menu>
            </Box>

            <Typography
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                textDecoration: "none",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: theme.palette.primary.light,
                }}
              >
                ZipBot.ai
              </Typography>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => page)}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip
                title={
                  authContext.loggedIn && authContext.userDetails
                    ? authContext.userDetails.name
                    : "Account"
                }
              >
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  {authContext.loggedIn && authContext.userDetails ? (
                    <Avatar
                      alt={authContext.userDetails.name}
                      src={authContext.userDetails.avatar_url}
                      sx={{ bgcolor: theme.palette.primary.light }}
                    />
                  ) : (
                    <Avatar>
                      <FaUserLarge />
                    </Avatar>
                  )}
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => setting)}
              </Menu>
            </Box>
          </Toolbar>
          <Toolbar
            id="appbar-content-toolbar"
            disableGutters
            sx={{
              bgcolor: theme.palette.background.default,
              color: theme.palette.primary.main,
            }}
          >
            {children}
          </Toolbar>
        </AppBar>
      </Box>
    </Container>
  );
}
export default AppMenu;
