import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Avatar,
  Grid,
  useTheme,
  Container,
  Badge,
} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import UploadProfilePhoto from "./UploadProfilePhoto";
import { useUserApi } from "../../api/userApi";

const UserProfile = () => {
  const theme = useTheme();
  const { fetchUserDetails, updateUserInfo } = useUserApi();

  const [profilePhotoOpen, setProfilePhotoOpen] = useState(false);
  const [userAvatarDisplay, setUserAvatarDisplay] = useState("");
  const [userAvatarFile, setUserAvatarFile] = useState(null);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(null);
  const [successDisplay, setSuccessDisplay] = useState(null);

  const passwordRef = useRef(null);
  const passwordConfirmationRef = useRef(null);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = () => {
    // If the password field is empty, clear the password confirmation field
    if (passwordRef.current.value === "") {
      passwordConfirmationRef.current.value = "";
      setPasswordsMatch(true);
    }

    // If the password and password confirmation fields do not match, show an error message
    if (passwordRef.current.value !== passwordConfirmationRef.current.value) {
      // Show an error message
      setPasswordsMatch(false);
      setErrorDisplay("Passwords do not match");
      return;
    }

    // If everything matches, set the password
    setPassword(passwordRef.current.value);
    setPasswordsMatch(true);
    setErrorDisplay(null);
  };

  useEffect(() => {
    // Fetch the user's profile
    const getUserDetails = async () => {
      const userDetails = await fetchUserDetails();

      setName(userDetails.name);
      setLocation(userDetails.location);
      setEmail(userDetails.email);
      setUserAvatarDisplay(userDetails.avatar_url);
    };

    getUserDetails();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await updateUserInfo(name, location, userAvatarFile, password);
      setSuccessDisplay("User info updated successfully");
    } catch (error) {
      setErrorDisplay("Failed to update user info");
    }
  };

  return (
    <Container
      disableGutters
      id="profile-container"
      maxWidth={false}
      sx={{
        // bgcolor: "rosybrown",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        color: theme.palette.text.primary,
        height: "calc(100dvh - 64px)",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        maxWidth: "350px",
      }}
    >
      <UploadProfilePhoto
        profilePhotoOpen={profilePhotoOpen}
        setProfilePhotoOpen={setProfilePhotoOpen}
        setUserAvatar={setUserAvatarDisplay}
        userAvatar={userAvatarDisplay}
        setUserAvatarFile={setUserAvatarFile}
      />
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        // color="secondary"
        badgeContent={
          <Avatar
            sx={{
              width: 26,
              height: 26,
              bgcolor: theme.palette.background.default,
            }}
          >
            <Button
              onClick={(e) => {
                setProfilePhotoOpen(true);
              }}
            >
              <AddAPhotoIcon
                color="primary"
                sx={{
                  width: 18,
                  height: 18,
                }}
              />
            </Button>
          </Avatar>
        }
      >
        <Avatar
          alt="User Avatar"
          src={userAvatarDisplay}
          sx={{ width: 64, height: 64, bgcolor: theme.palette.info.main }}
        />
      </Badge>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          value={name}
          onChange={handleNameChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Location"
          value={location}
          onChange={handleLocationChange}
          fullWidth
          margin="normal"
        />
        <TextField
          inputRef={passwordRef}
          label="Change Password"
          type="password"
          onChange={handlePasswordChange}
          fullWidth
          margin="normal"
        />
        <TextField
          inputRef={passwordConfirmationRef}
          label="Password Confirmation"
          type="password"
          onChange={handlePasswordChange}
          fullWidth
          margin="normal"
          error={!passwordsMatch}
          sx={{
            display: passwordRef.current?.value ? "block" : "none",
          }}
        />

        {/* Display an error if something is wrong */}
        <Typography
          variant="caption"
          color="error"
          sx={{
            display: errorDisplay ? "block" : "none",
            margin: "2px",
          }}
        >
          {errorDisplay}
        </Typography>
        <Typography
          variant="caption"
          color={theme.palette.success.main}
          sx={{
            display: successDisplay ? "block" : "none",
            margin: "2px",
          }}
        >
          {successDisplay}
        </Typography>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!passwordsMatch && passwordRef.current?.value !== ""}
        >
          Save
        </Button>
      </form>
    </Container>
  );
};

export default UserProfile;
