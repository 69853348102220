import { Box, Container, Tab, Tabs, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";

import BetaApplications from "./BetaApplications";

const Administration = () => {
  const theme = useTheme();

  const [selectedTabValue, setSelectedTabValue] = useState(0);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`administration-tabpanel-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTabValue(newValue);
  };

  return (
    <Container
      disableGutters
      id="administration-container"
      maxWidth={false}
      sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        color: theme.palette.text.primary,
        height: "calc(100dvh - 64px)",
      }}
    >
      <Tabs centered value={selectedTabValue} onChange={handleTabChange}>
        <Tab label="Beta Applications" />
        <Tab label="Users" />
        <Tab label="Token Usage" />
      </Tabs>

      <CustomTabPanel value={selectedTabValue} index={0}>
        <BetaApplications />
      </CustomTabPanel>

      <CustomTabPanel value={selectedTabValue} index={1}>
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          User Administration Coming Soon
        </Typography>
      </CustomTabPanel>

      <CustomTabPanel value={selectedTabValue} index={2}>
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Token Usage Administration Coming Soon
        </Typography>
      </CustomTabPanel>
    </Container>
  );
};

export default Administration;
