import {
  Avatar,
  Box,
  Button,
  IconButton,
  ListItem,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
  keyframes,
  useMediaQuery,
} from "@mui/material";
import { memo, useCallback, useContext } from "react";
import { IoWarning } from "react-icons/io5";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";
import { AuthContext } from "../../../context/AuthContext";
import { useState } from "react";
import MessageSettingsMenu from "./MessageSettingsMenu";
import FileDisplay from "./FileDisplay";
import ErrorIcon from "@mui/icons-material/Error";
import { useConversationsApi } from "../../../api/conversationsApi";

const MessageHistoryItem = memo(
  ({ conversationId, msg, index, theme, sidebarSize, setMessageHistory }) => {
    const authContext = useContext(AuthContext);

    const [copiedToClipboardOpen, setCopiedToClipboardOpen] = useState(false);
    const { removeFileFromMessageAndConversation } = useConversationsApi();

    if (!useMediaQuery(theme.breakpoints.up("sm"))) {
      sidebarSize = 75;
    } else {
      sidebarSize = sidebarSize + 75;
    }

    const messageTextWidth = "calc(100vw - " + sidebarSize + "px)";
    const codeBlockMaxWidth = "calc(" + messageTextWidth + " - 30px)";

    const handleCopiedToClipboardClose = useCallback((event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setCopiedToClipboardOpen(false);
    }, []);

    const handleRemoveFileFromMessage = useCallback(
      async (file) => {
        if (!conversationId) {
          console.error("Conversation ID not found for file removal: ", msg);
          return;
        }

        if (!msg.id) {
          console.error("Message ID not found for file removal: ", msg);
          return;
        }

        if (!file || !file.file_name) {
          console.error("File or file_name not found for file removal: ", file);
          return;
        }

        await removeFileFromMessageAndConversation(
          conversationId,
          msg.id,
          file.key
        );

        setMessageHistory((prevMessages) => {
          const newMessages = [...prevMessages];
          const messageIndex = newMessages.findIndex(
            (oldMsg) => oldMsg.id === msg.id
          );
          if (messageIndex === -1) {
            console.error("Message not found for file removal: ", msg.id);
            return newMessages;
          }

          const message = { ...newMessages[messageIndex] };

          const newAssociatedFiles = message.associated_files;

          // Loop through the associated files and remove the file with the matching name
          // NOTE: This has to be the file_name because the key is not available in the message immediately after attaching it
          for (let index in newAssociatedFiles) {
            if (newAssociatedFiles[index].file_name === file.file_name) {
              delete newAssociatedFiles[index];
              break;
            }
          }

          const updatedMessage = {
            ...message,
            associated_files: newAssociatedFiles,
          };

          newMessages[messageIndex] = updatedMessage;
          return newMessages;
        });
      },
      [removeFileFromMessageAndConversation, setMessageHistory]
    );

    return (
      <ListItem
        id={"message-history-item-" + (msg.id ? msg.id : index)}
        key={"message-history-item-" + (msg.id ? msg.id : index)}
        sx={{
          mt: 5,
          ml: 5,
          pb: 0,
          mb: 0,
          borderRadius: "15px",
          width: messageTextWidth,
          color: theme.palette.text.primary,
          bgcolor:
            msg.role === "user"
              ? theme.palette.conversation.user.bgcolor
              : theme.palette.conversation.bot.bgcolor,
        }}
      >
        <Box
          flexDirection={"column"}
          sx={{
            display: "flex",
            flexGrow: 1,
            // bgcolor: "blue",
            p: 0,
            m: 0,
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              flexGrow: 1,
              alignContent: "center",
              alignItems: "center",
              width: "100%",
              // bgcolor: "red",
            }}
            spacing={2}
          >
            <Avatar
              alt={msg.role === "user" ? authContext.userDetails.name : "Zippy"}
              src={
                msg.role === "user"
                  ? authContext.userDetails.avatar_url
                  : theme.zippy.image.src
              }
              sx={{
                boxShadow: "none",
                bgcolor:
                  msg.role === "user"
                    ? theme.palette.primary.light
                    : "transparent",
              }}
            />

            <Typography sx={{ fontWeight: theme.typography.fontWeightBold }}>
              {" "}
              {msg.role === "user"
                ? authContext.userDetails.name
                : "Zippy"}{" "}
            </Typography>

            <Tooltip
              title="There was an error processing your request"
              sx={{ display: msg.role === "error" ? "block" : "none" }}
            >
              <ErrorIcon
                sx={{
                  color: theme.palette.primary.light,
                  display: msg.role === "error" ? "block" : "none",
                }}
              />
            </Tooltip>

            <Box
              id={"message-settings-box-" + (msg.id ? msg.id : index)}
              style={{
                flexGrow: 1,
                alignContent: "right",
              }}
            >
              <MessageSettingsMenu
                conversationId={conversationId}
                message={msg}
                setCopiedToClipboardOpen={setCopiedToClipboardOpen}
              />
            </Box>
          </Stack>

          <Box
            key={"message-box-" + (msg.id ? msg.id : index)}
            id={"message-box-" + (msg.id ? msg.id : index)}
            sx={{
              pb: "16px",
              pt: 0,
              mt: 0,
            }}
          >
            <Box
              className="reactMarkDown"
              key={"react-markdown-box-" + (msg.id ? msg.id : index)}
              id={"react-markdown-box-" + (msg.id ? msg.id : index)}
            >
              <ReactMarkdown
                id={"react-markdown-" + (msg.id ? msg.id : index)}
                key={"react-markdown-" + (msg.id ? msg.id : index)}
                children={msg.content}
                className="reactMarkDown"
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeKatex]}
                components={{
                  code({ node, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    let lang = "text";
                    if (match) {
                      lang = match[1].toLowerCase();
                    }

                    return lang !== "text" ? (
                      // if the code block has a language, use SyntaxHighlighter
                      <Box
                        key={"code-block-" + (msg.id ? msg.id : index)}
                        id={"code-block-" + (msg.id ? msg.id : index)}
                        sx={{
                          overflowX: "auto",
                          maxWidth: codeBlockMaxWidth,
                          pl: 1,
                          pr: 2,
                          justifyContent: "center",
                          //  bgcolor: "red",
                        }}
                      >
                        <Button
                          sx={{
                            id:
                              "copy-to-clipboard-button-" +
                              (msg.id ? msg.id : index),
                            key:
                              "copy-to-clipboard-button-" +
                              (msg.id ? msg.id : index),
                            position: "absolute",
                            right: "20px",
                            marginTop: "5px",
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(String(children));
                            setCopiedToClipboardOpen(true);
                          }}
                        >
                          <ContentCopyTwoToneIcon sx={{ margin: "4px" }} />
                        </Button>

                        <SyntaxHighlighter
                          id={"syntax-highlighter-" + (msg.id ? msg.id : index)}
                          key={
                            "syntax-highlighter-" + (msg.id ? msg.id : index)
                          }
                          children={String(children)}
                          style={theme.palette.code.codeTheme}
                          language={lang}
                          PreTag="div"
                          wrapLines={true}
                          {...props}
                        />
                      </Box>
                    ) : (
                      // else, use a box
                      <Box
                        {...props}
                        id={"code-block-" + (msg.id ? msg.id : index)}
                        key={"code-block-" + (msg.id ? msg.id : index)}
                        sx={{
                          display: "inline-flex",
                          color: theme.palette.code.codeColor,
                          bgcolor: theme.palette.code.codeBackground,
                          fontWeight: theme.typography.code.fontWeight,
                          overflowX: "auto",
                          maxWidth: codeBlockMaxWidth,
                          pl: 1,
                          pr: 2,
                          m: 0,
                          p: "0px 5px 1px 5px",
                          borderRadius: "5px",
                          fontSize: "0.8em",
                          fontFamily: theme.typography.code.fontFamily,
                        }}
                      >
                        {children}
                      </Box>
                    );
                  },
                }}
              />
              {msg.associated_files &&
                Object.keys(msg.associated_files).length > 0 && (
                  <FileDisplay
                    id={"files-display-" + (msg.id ? msg.id : index)}
                    key={"files-display-" + (msg.id ? msg.id : index)}
                    files={msg.associated_files}
                    removeFile={handleRemoveFileFromMessage}
                    isMessage={true}
                    canRemove={msg.id ? true : false}
                  />
                )}
            </Box>
          </Box>
          {msg.role !== "user" && msg.disclaimer && (
            <Tooltip sx={{ pt: 0 }} title={`"Disclaimer: ${msg.disclaimer}"`}>
              <IconButton
                style={{
                  top: "-15px",
                  height: "35px",
                  width: "35px",
                }}
              >
                <IoWarning color={theme.palette.warning.main} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Snackbar
          id={"snack-bar-" + (msg.id ? msg.id : index)}
          key={"snack-bar-" + (msg.id ? msg.id : index)}
          open={copiedToClipboardOpen}
          autoHideDuration={1000}
          onClose={handleCopiedToClipboardClose}
          message="Copied to clipboard"
          sx={{ mt: "50px" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </ListItem>
    );
  }
);

export default MessageHistoryItem;
