import { jwtDecode } from "jwt-decode";
export const useTokens = () => {
  const AUTH_API_HOST = `${window.env.REACT_APP_AUTH_API_HOST}`;

  const checkAccessToken = async () => {
    // Start a timer to time this function
    let authToken = localStorage.getItem("access_token");

    if (!authToken || authToken === "undefined") {
      console.warn("No auth token available. Attempting to refresh token.");
      authToken = await refreshToken();

      if (!authToken) {
        console.error("Failed to refresh token or no auth token available.");
        return null;
      }
    }

    let decodedToken = jwtDecode(authToken);
    let expirationDate = new Date(decodedToken.exp * 1000); // Convert from seconds to milliseconds

    if (expirationDate < new Date()) {
      authToken = await refreshToken();

      if (!authToken) {
        console.error("Failed to refresh token or no auth token available.");
        return null;
      }
    }

    return authToken;
  };

  const refreshToken = async () => {
    try {
      const response = await fetch(`${AUTH_API_HOST}/refresh`, {
        method: "POST",
        credentials: "include", // Ensure credentials are included
      });

      if (!response.ok) {
        throw new Error("Token refresh failed");
      }

      // Get the access_token from the response and set it in local storage
      const data = await response.json();
      localStorage.setItem("access_token", data.access_token);

      return data.access_token;
    } catch (error) {
      throw new Error("Token refresh failed");
    }
  };

  return { refreshToken, checkAccessToken };
};
