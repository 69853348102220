import { Avatar, AvatarGroup, Box, Tooltip, useTheme } from "@mui/material";
import { ConversationContext } from "../providers/ConversationProvider";
import { useContext } from "react";

export default function ActiveAgents(props) {
  const theme = useTheme();

  const { conversationAgents } = useContext(ConversationContext);

  const { sx, onClick, ...otherProps } = props;

  return (
    <Box
      sx={{
        ...sx,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <AvatarGroup max={10}>
        <Tooltip
          key={"tooltip-zippy"}
          title="Zippy is active in this conversation, click to add or remove other AI agents."
          placement="bottom"
        >
          <Avatar
            key={"avatar-zippy"}
            sx={{ height: 30, width: 30 }}
            alt="Zippy"
            src="/zippy.png"
          />
        </Tooltip>

        {conversationAgents.length > 0 &&
          conversationAgents.map((agent) => (
            <Tooltip
              key={"tooltip-" + agent.id}
              title={`${agent.name} is active in this conversation, click to add or remove other AI agents.`}
              placement="bottom"
            >
              <Avatar
                key={"avatar-" + agent.id}
                sx={{
                  height: 30,
                  width: 30,
                  bgcolor: theme.palette.secondary.light,
                }}
                alt={agent.name}
                src={agent.image_url}
              >
                {agent.name[0]}
              </Avatar>
            </Tooltip>
          ))}
      </AvatarGroup>
    </Box>
  );
}
