import { useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function Logout() {
  const navigate = useNavigate();
  const { logout, setLoggedIn } = useAuth();

  useEffect(() => {
    const logTheFuckOut = async () => {
      await logout();
      setLoggedIn(false);
      navigate("/");
    };

    logTheFuckOut();
  }, []);

  // Return a loading indicator until the redirect completes
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
