import {
  vs,
  vscDarkPlus,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { createTheme } from "@mui/material/styles";

import {
  lightBlue,
  blueGrey,
  deepOrange,
  blue,
  green,
  grey,
  lightGreen,
} from "@mui/material/colors";

function getTheme(themeMode) {
  let theme = createTheme({
    zippy: {
      image: {
        src: "/zippy.png",
      },
      icon: {
        src: "/zippy-32x32.ico",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
      },
    },
    typography: {
      code: {
        fontFamily:
          '"Consolas", "Monaco", "Andale Mono", "Ubuntu Mono", monospace',
        fontWeight: 900,
      },
      thought: {
        fontWeight: 500,
        fontSize: ".7rem",
      },
      thoughtHeader: {
        fontWeight: 900,
        fontSize: ".8rem",
        fontStyle: "italic",
      },
    },
  });

  if (themeMode === "light") {
    theme = createTheme(theme, {
      palette: {
        mode: "light",
        primary: {
          main: "#e65100",
          light: "rgb(235, 115, 51)",
          dark: "rgb(161, 56, 0)",
          contrastText: "black",
        },
        secondary: {
          main: "#9e073d",
          light: "rgb(177, 56, 99)",
          dark: "rgb(110, 4, 42)",
          contrastText: "white",
        },
        error: {
          main: "#d40f00",
        },
        background: {
          default: "#fafafa",
          paper: "#fff",
          light: "#f5f5f5",
        },
        text: {
          primary: "rgba(0, 0, 0, 0.87)",
          secondary: "rgba(0, 0, 0, 0.54)",
          disabled: "rgba(0, 0, 0, 0.38)",
          hint: "rgba(0, 0, 0, 0.38)",
        },
        code: {
          codeBackground: grey[200], //"#919191",
          codeColor: green[900],
          codeTheme: vs,
        },
        conversation: {
          user: {
            color: lightBlue[500],
            bgcolor: blueGrey[50],
          },
          bot: {
            color: blue[500],
            bgcolor: blueGrey[100],
          },
          error: {
            color: deepOrange[500],
            bgcolor: blueGrey[100],
          },
        },
      },
      components: {
        MuiMenuItem: {
          styleOverrides: {
            root: {
              "&:hover": {
                backgroundColor: "transparent", // Change this to your desired hover color
              },
            },
          },
        },
      },
    });
  } else {
    theme = createTheme(theme, {
      palette: {
        mode: "dark",
        primary: {
          main: "#e65100",
          light: "rgb(235, 115, 51)",
          dark: "rgb(161, 56, 0)",
          contrastText: "#fff",
        },
        secondary: {
          main: "#9e073d",
          light: "rgb(177, 56, 99)",
          dark: "rgb(110, 4, 42)",
          contrastText: "#fff",
        },
        error: {
          main: "#d40f00",
        },
        background: {
          default: "#212121",
          paper: "#424242",
          light: "#303030",
        },
        text: {
          primary: "#ffffff",
          secondary: "rgba(255, 255, 255, 0.7)",
          disabled: "rgba(255, 255, 255, 0.5)",
          hint: "rgba(255, 255, 255, 0.5)",
        },
        code: {
          codeBackground: grey[800], //"#919191",
          codeColor: lightGreen[500],
          codeTheme: vscDarkPlus,
        },
        conversation: {
          user: {
            color: lightBlue[500],
            bgcolor: grey[700],
          },
          bot: {
            color: blue[500],
            bgcolor: "rgba(69,90,100,0.81)",
          },
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            a: {
              // Set the color of the link to the primary color
              color: "#e65100",
              // Set the color of clicked links to the secondary color
              "&:visited": {
                color: "rgb(161, 56, 0)",
              },
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              "&:hover": {
                backgroundColor: "transparent", // Change this to your desired hover color
              },
            },
          },
        },
      },
    });
  }

  return theme;
}

export default getTheme;
