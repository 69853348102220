import { useCustomFetch } from "./customFetch";

const DOCUMENTS_API_HOST = `${window.env.REACT_APP_DOCUMENTS_API_HOST}`;

export const useDocumentsApi = () => {
  const { customFetch } = useCustomFetch();

  const fetchUploadUrl = async (file) => {
    try {
      const jsonBody = JSON.stringify({
        file_name: file.file_name,
        file_type: file.file_type,
        // conversation_id: conversationId Don't put this in here- put it into the query
      });
      const response = await customFetch(
        `${DOCUMENTS_API_HOST}/initiate-upload`,
        {
          method: "PUT",
          credentials: "include", // include cookies
          body: jsonBody,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to initiate document upload");
      }

      const uploadUrlDetails = await response.json();

      return uploadUrlDetails;
    } catch (error) {
      throw error;
    }
  };

  const uploadDocument = async (file, uploadDetails, progressCallback) => {
    return new Promise((resolve, reject) => {
      const url = uploadDetails.upload_url; // The URL to upload the file
      const xhr = new XMLHttpRequest();

      xhr.open("PUT", url, true);

      xhr.setRequestHeader("Content-Type", file.type);

      xhr.upload.onprogress = function (event) {
        if (event.lengthComputable) {
          const percentage = (event.loaded / event.total) * 100;
          progressCallback(file.name, percentage);
        }
      };

      xhr.onload = function () {
        if (xhr.status === 200) {
          // Tell the server that we are done uploading the file
          completeUpload(uploadDetails.key);

          // Try to ingest the file
          // NOTE: This is just the initial task that needs to be run on the file to do anything with it
          // TODO: Make it so the user can select "default" tasks to run on the file
          // and then use the ingest-file endpoint.
          runDocumentTask(uploadDetails.key, "ingest_and_split_document");

          // Sometimes this doesn't get called for some reason in the progress loop
          // but if we are here, then the file is complete.
          progressCallback(file.name, 100);
          resolve();
        } else {
          reject(new Error("Failed to upload document"));
        }
      };

      xhr.onerror = function () {
        reject(new Error("Failed to upload document"));
      };

      xhr.send(file);
    });
  };

  const completeUpload = async (key) => {
    try {
      const response = await customFetch(
        `${DOCUMENTS_API_HOST}/complete-upload`,
        {
          method: "PUT",
          credentials: "include", // include cookies
          body: key,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to complete document upload");
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchConversationFiles = async (conversationId) => {
    try {
      const response = await customFetch(
        `${DOCUMENTS_API_HOST}/conversation-files?conversation_id=${conversationId}`,
        {
          method: "GET",
          credentials: "include", // include cookies
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch conversation files");
        return null;
      }

      const files = await response.json();

      return files;
    } catch (error) {
      throw error;
    }
  };

  const fetchDocumentCollections = async () => {
    try {
      const response = await customFetch(
        `${DOCUMENTS_API_HOST}/document-collections`,
        {
          method: "GET",
          credentials: "include", // include cookies
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch document collections");
        return [];
      }

      const documentCollections = await response.json();

      return documentCollections;
    } catch (error) {
      throw error;
    }
  };

  const createDocumentCollection = async (collectionName) => {
    try {
      const jsonBody = JSON.stringify(collectionName);
      const response = await customFetch(
        `${DOCUMENTS_API_HOST}/document-collections`,
        {
          method: "POST",
          credentials: "include", // include cookies
          body: jsonBody,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create document collection");
      }

      const documentCollection = await response.json();

      return documentCollection;
    } catch (error) {
      throw error;
    }
  };

  const getDocumentCollectionFiles = async (collectionId) => {
    try {
      const response = await customFetch(
        `${DOCUMENTS_API_HOST}/document-collections/${collectionId}/files`,
        {
          method: "GET",
          credentials: "include", // include cookies
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch document collection files");
        return [];
      }

      const documentCollectionFiles = await response.json();

      return documentCollectionFiles;
    } catch (error) {
      console.error("Failed to fetch document collection files ", error);
      return [];
    }
  };

  const getAllFiles = async () => {
    try {
      const response = await customFetch(`${DOCUMENTS_API_HOST}/files`, {
        method: "GET",
        credentials: "include", // include cookies
      });

      if (!response.ok) {
        console.error("Failed to fetch all files");
        return [];
      }

      const allFiles = await response.json();

      return allFiles;
    } catch (error) {
      console.error("Failed to fetch all files ", error);
      return [];
    }
  };

  const getAllUploadedFilesForConversationDisplay = async () => {
    try {
      const response = await customFetch(
        `${DOCUMENTS_API_HOST}/uploaded-files`,
        {
          method: "GET",
          credentials: "include", // include cookies
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch all files");
        return [];
      }

      const allFiles = await response.json();

      return allFiles;
    } catch (error) {
      console.error("Failed to fetch all files ", error);
      return [];
    }
  };

  const associateFilesWithConversation = async (conversationId, files) => {
    try {
      const jsonBody = JSON.stringify({
        conversation_id: conversationId,
        file_keys: files.map((file) => file.ui_key),
      });

      const response = await customFetch(
        `${DOCUMENTS_API_HOST}/conversation-files`,
        {
          method: "POST",
          credentials: "include", // include cookies
          body: jsonBody,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add files to conversation");
      }
    } catch (error) {
      throw error;
    }
  };

  const deleteFiles = async (fileKeys) => {
    try {
      const jsonBody = JSON.stringify(fileKeys);
      const response = await customFetch(`${DOCUMENTS_API_HOST}/files`, {
        method: "DELETE",
        credentials: "include", // include cookies
        body: jsonBody,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete files");
      }
    } catch (error) {
      throw error;
    }
  };

  const deleteDocumentCollection = async (collectionId) => {
    try {
      const response = await customFetch(
        `${DOCUMENTS_API_HOST}/document-collections/${collectionId}`,
        {
          method: "DELETE",
          credentials: "include", // include cookies
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete document collection");
      }
    } catch (error) {
      throw error;
    }
  };

  const getDownloadUrl = async (ui_key) => {
    try {
      const response = await customFetch(
        `${DOCUMENTS_API_HOST}/get-file-url/${ui_key}`,
        {
          method: "GET",
          credentials: "include", // include cookies
        }
      );

      if (!response.ok) {
        console.error("Failed to get download URL");
        return null;
      }

      const downloadDetails = await response.json();

      return downloadDetails.download_url;
    } catch (error) {
      throw error;
    }
  };

  const runDocumentTask = async (ui_key, task_name) => {
    try {
      const jsonBody = JSON.stringify({
        ui_key: ui_key,
        task_name: task_name,
      });
      const response = await customFetch(
        `${DOCUMENTS_API_HOST}/task/document-task`,
        {
          method: "POST",
          credentials: "include", // include cookies
          body: jsonBody,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        if (response.status === 204) {
          console.error("File type cannot be ingested");
        }

        return null;
      }
    } catch (error) {
      throw error;
    }
  };

  return {
    fetchUploadUrl,
    uploadDocument,
    fetchConversationFiles,
    fetchDocumentCollections,
    createDocumentCollection,
    getDocumentCollectionFiles,
    getAllFiles,
    getAllUploadedFilesForConversationDisplay,
    deleteFiles,
    deleteDocumentCollection,
    getDownloadUrl,
    runDocumentTask,
    associateFilesWithConversation,
  };
};
